import React from "react";
import NavBar from "../../Layout/Navbar";
import SectionContact from "./Section";
import ContactForm from "./ContactFormPage";
import FooterAlt from "../../Layout/FooterAlt";
import BackToTop from "../../components/BackToTop";
import Footer from "../../Layout/Footer";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  const darkMode = useSelector((state) => state.darkMode);
  return (
    <React.Fragment>
      <Helmet>
        <title>Data</title>
      </Helmet>
      <NavBar isDark={darkMode} />
      <SectionContact />
      <ContactForm />
      <Footer />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};

export default ContactPage;
