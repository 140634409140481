import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getVacancyList = createAsyncThunk(
  "vacancy/list",
  async (_, thunkAPI) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/vacancy/list`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getVacancyDetail = createAsyncThunk(
  "vacancy/detail",
  async (slug, thunkAPI) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/vacancy/detail`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          slug: slug,
        },
      });

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  vacancyLoading: false,
  vacancyList: null,
  vacancyDetailLoading: false,
  vacancyDetail: null
};

const vacancySlice = createSlice({
  name: "vacancy",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getVacancyList.pending, (state) => {
        state.vacancyLoading = true;
      })
      .addCase(getVacancyList.fulfilled, (state, action) => {
        state.vacancyLoading = false;
        state.vacancyList = action.payload;
      })
      .addCase(getVacancyList.rejected, (state) => {
        state.vacancyLoading = false;
      })
      .addCase(getVacancyDetail.pending, (state) => {
        state.vacancyDetailLoading = true;
      })
      .addCase(getVacancyDetail.fulfilled, (state, action) => {
        state.vacancyDetailLoading = false;
        state.vacancyDetail= action.payload;
      })
      .addCase(getVacancyDetail.rejected, (state) => {
        state.vacancyDetailLoading = false;
      });
  },
});

export default vacancySlice.reducer;
