import React from "react";
import { Link } from "react-router-dom";
import "./blog.scss";
import { Col, Row } from "reactstrap";

const BlogList = ({ blogs }) => {
  return (
    <Row>
      {blogs.map((blog) => (
        <Col lg="4" md="4" sm="auto" xs="auto" key={blog.id}>
          <div className="blog-box box-shadow rounded mt-4 p-3">
            <div className="blog-img">
              <img src={blog.thumbnail} className="img-fluid rounded" alt="" />
              <div className="read-more">
                <Link to={`/blog/${blog.slug}`}>
                  <i className="mdi mdi-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="mt-4">
              <Link to={`/blog/${blog.slug}`} className="primary-link">
                <h5 className="fs-19 mb-1">{blog.title}</h5>
              </Link>
              <p className="text-muted mt-2">{blog.subtitle}</p>

              <div className="d-flex align-items-center justify-content-between"></div>
              <Row className="justify-content-center m-4">
                <Col xs="auto" md="auto">
                  <Link to={`/blog/${blog.slug}`} className="btn btn-primary">
                    Read More
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
          {/* <!--end blog--> */}
        </Col>
      ))}
    </Row>
  );
};

export default BlogList;
