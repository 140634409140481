import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DatePickerComponent from "../../components/select/SelectDate";
import SwitchComponent from "../../components/switch/Switch";
import { BulletList } from "react-content-loader";
import AutocompleteComponent from "../../components/autocomplete/Autocomplete";
import {
  getCustomerList,
  getFilterTypeList,
  getCustomerUserList,
  fetchFilterValueList,
  getReportGroupList,
  getAdditionalUserInfo,
  getSelectedUserInfo,
  posUserAccountUpdate,
} from "slice/admin";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import Grid from "@mui/material/Grid";

function UserAccountUpdateComponent() {
  const {
    customerList,
    filterTypeList,
    customerUserList,
    customerUserListLoading,
    filterValueList,
    filterValueListLoading,
    reportGroupList,
    reportGroupListLoading,
    selectedUserInfo,
    additionalUserInfo,
  } = useSelector((state) => state.adminInfo);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCustomer, setCustomer] = useState(null);
  const [selectedFilterType, setFiltertype] = useState(null);
  const [selectedFilterValue, setFiltervalue] = useState(null);
  const [selectedUserGroup, setSelectedUserGroup] = useState(null);
  const [selectedUserGender, setSelectedUserGender] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [dobValue, setDobValue] = useState(dayjs("2000-01-01"));
  const [checked, setChecked] = useState(false);

  const [formData, setFormData] = useState({
    user: null,
    customer: null,
    filtertype: null,
    filtervalue: null,
    userdob: null,
    usergroup: null,
    usergender: null,
    usertype: null,
    isactive: null,
  });

  const UserListMapped = customerUserList
    ? customerUserList.map(({ id, full_name }) => ({
        label: full_name,
        value: id.toString(),
      }))
    : null;

  const customerListMapped = customerList
    ? customerList.map(({ id, name }) => ({
        label: name,
        value: id.toString(),
      }))
    : null;

  const filterTypeListMapped = filterTypeList
    ? filterTypeList.map(({ id, name }) => ({
        label: name,
        value: id.toString(),
      }))
    : null;

  const filterValueListMapped = filterValueList
    ? filterValueList.map(({ id, description }) => ({
        label: description,
        value: id.toString(),
      }))
    : null;

  const reportGroupListMapped = reportGroupList
    ? reportGroupList.map(({ id, name }) => ({
        label: name,
        value: id.toString(),
      }))
    : null;

  const userGenderListMapped =
    additionalUserInfo && additionalUserInfo.user_genders
      ? additionalUserInfo.user_genders.map(({ id, description }) => ({
          label: description,
          value: id.toString(),
        }))
      : null;

  const userTypesListMapped =
    additionalUserInfo && additionalUserInfo.user_types
      ? additionalUserInfo.user_types.map(({ id, description }) => ({
          label: description,
          value: id.toString(),
        }))
      : null;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCustomerList());
    dispatch(getCustomerUserList());
    dispatch(getAdditionalUserInfo());
  }, [dispatch]);

  function updateDefaultFormValue(defaultIdValue, existingList, setFunction) {
    if (existingList) {
      for (let item of existingList) {
        let label;

        switch (true) {
          case !!item.full_name:
            label = item.full_name;
            break;
          case !!item.name:
            label = item.name;
            break;
          case !!item.description:
            label = item.description;
            break;
          default:
            label = "Undefined";
        }

        const value = parseInt(item.id);

        if (value === parseInt(defaultIdValue)) {
          setFunction({ label, value });
          return;
        }
      }
    }

    setFunction(null);
  }

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      user: selectedUser ? selectedUser.value : null,
      customer: selectedCustomer ? selectedCustomer.value : null,
      filtertype: selectedFilterType ? selectedFilterType.value : null,
      isactive: checked,
      usergender: selectedUserGender ? selectedUserGender.value : null,
      filtervalue: selectedFilterValue ? selectedFilterValue.value : null,
      usergroup: selectedUserGroup ? selectedUserGroup.value : null,
      userdob: dobValue ? dobValue.value : null,
      usertype: selectedUserType ? selectedUserType.value : null,
    }));
  }, [
    selectedUser,
    selectedCustomer,
    selectedFilterType,
    selectedFilterValue,
    selectedUserGroup,
    selectedUserGender,
    dobValue,
    selectedUserType,
    checked,
  ]);

  // ------------------PROBLEM AREA----------------------------

  useEffect(() => {
    if (selectedUserInfo && selectedUserInfo) {
      updateDefaultFormValue(
        selectedUserInfo.customer_id,
        customerList,
        setCustomer
      );

      updateDefaultFormValue(
        selectedUserInfo.gender_id,
        additionalUserInfo.user_genders,
        setSelectedUserGender
      );

      updateDefaultFormValue(
        selectedUserInfo.type_id,
        additionalUserInfo.user_types,
        setSelectedUserType
      );

      if (selectedUserInfo) {
        setDobValue(dayjs(selectedUserInfo.user_dob));
      }

      setChecked(selectedUserInfo.is_active);
    }
  }, [selectedUserInfo]);

  useEffect(() => {
    if (selectedCustomer) {
      setFiltertype(null);
      dispatch(getFilterTypeList());
      setSelectedUserGroup(null);
      dispatch(getReportGroupList(selectedCustomer.value));
    }
  }, [dispatch, selectedCustomer]);

  useEffect(() => {
    if (selectedFilterType) {
      setFiltervalue(null);
      dispatch(
        fetchFilterValueList({
          customer: selectedCustomer.value,
          type: selectedFilterType.value,
        })
      );
    }
  }, [dispatch, selectedFilterType]);

  useEffect(() => {
    if (filterTypeList && selectedUserInfo) {
      updateDefaultFormValue(
        selectedUserInfo.filter_type_id,
        filterTypeList,
        setFiltertype
      );
    }
  }, [filterTypeList]);

  useEffect(() => {
    if (reportGroupList && selectedUserInfo) {
      updateDefaultFormValue(
        selectedUserInfo.user_group_id,
        reportGroupList,
        setSelectedUserGroup
      );
    }
  }, [reportGroupList]);

  useEffect(() => {
    if (filterValueList && selectedUserInfo) {
      updateDefaultFormValue(
        selectedUserInfo.filter_value_id,
        filterValueList,
        setFiltervalue
      );
    }
  }, [filterValueList]);

  // ------------------END PROBLEM AREA----------------------------

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
    setFormData({ ...formData, isactive: event.target.checked });
  };

  const handleDateChange = (event) => {
    setDobValue(event);
    setFormData((prevFormData) => ({
      ...prevFormData,
      userdob: event,
    }));
  };

  const handleInputChange = (event, value, name) => {
    switch (name) {
      case "user":
        dispatch(getSelectedUserInfo(value.value));
        setSelectedUser(value);
        break;
      case "customer":
        setCustomer(value);
        break;
      case "filtertype":
        setFiltertype(value);
        break;
      case "filtervalue":
        setFiltervalue(value);
        break;
      case "usergroup":
        setSelectedUserGroup(value);
        break;
      case "usergender":
        setSelectedUserGender(value);
        break;
      case "usertype":
        setSelectedUserType(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Check if any formData item is empty
    const isFormDataValid = true;

    // Object.values(formData).every(
    //   (value) => value !== null
    // );
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (isFormDataValid) {
      dispatch(posUserAccountUpdate(formData)).then((e) => {
        if (!e.error) {
          setSelectedUser(null);
          setCustomer(null);
          setFiltertype(null);
          setFiltervalue(null);
          setSelectedUserGroup(null);
          setSelectedUserGender(null);
          setSelectedUserType(null);
          setDobValue(dayjs("2000-01-01"));
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      });
    } else {
      const emptyField = Object.keys(formData).find(
        (key) => formData[key] === null
      );
      console.log(`Please fill out the "${emptyField}" field.`);
    }
  };

  const colArray = [];
  for (let i = 0; i < 2; i++) {
    colArray.push(
      <Grid item xs={12} md={12} lg={12} key={i}>
        <BulletList />
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {!customerUserListLoading ? (
        <>
          <AutocompleteComponent
            title="User"
            name="user"
            options={UserListMapped}
            value={selectedUser}
            isMultiple={false}
            onChange={handleInputChange}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          />

          {selectedUser ? (
            <AutocompleteComponent
              title="Customer"
              name="customer"
              options={customerListMapped}
              value={selectedCustomer}
              isMultiple={false}
              onChange={handleInputChange}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            />
          ) : (
            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <div></div>
            </Grid>
          )}

          {reportGroupListLoading ? (
            <Grid item xs="auto" md="auto" lg="auto">
              {colArray}
            </Grid>
          ) : (
            <>
              {selectedUser && selectedCustomer ? (
                <>
                  <AutocompleteComponent
                    title="User Group"
                    name="usergroup"
                    options={reportGroupListMapped}
                    value={selectedUserGroup}
                    isMultiple={false}
                    onChange={handleInputChange}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  />
                  <AutocompleteComponent
                    title="Filter Type"
                    name="filtertype"
                    options={filterTypeListMapped}
                    value={selectedFilterType}
                    isMultiple={false}
                    onChange={handleInputChange}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  />
                </>
              ) : (
                <Grid item xs="auto" md="auto" lg="auto">
                  <div></div>
                </Grid>
              )}
            </>
          )}

          {filterValueListLoading ? (
            <Grid item xs="auto" md="auto" lg="auto">
              {colArray}
            </Grid>
          ) : (
            <>
              {selectedUser && selectedCustomer && selectedFilterType ? (
                <>
                  <AutocompleteComponent
                    title="Filter Value"
                    name="filtervalue"
                    options={filterValueListMapped}
                    value={selectedFilterValue}
                    isMultiple={false}
                    onChange={handleInputChange}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  />

                  <DatePickerComponent
                    value={dobValue}
                    name="userdob"
                    label="Date of Birth"
                    onChange={handleDateChange}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  />
                  <AutocompleteComponent
                    title="Gender"
                    name="usergender"
                    options={userGenderListMapped}
                    value={selectedUserGender}
                    isMultiple={false}
                    onChange={handleInputChange}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  />
                  <AutocompleteComponent
                    title="Type"
                    name="usertype"
                    options={userTypesListMapped}
                    value={selectedUserType}
                    isMultiple={false}
                    onChange={handleInputChange}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  />
                  <SwitchComponent
                    label="Is active"
                    checked={checked}
                    onChange={handleSwitchChange}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  />
                </>
              ) : (
                <Grid item xs="auto" md="auto" lg="auto">
                  <div></div>
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </Button>
          </Grid>
        </>
      ) : (
        <Grid item xs="auto" md="auto" lg="auto">
          {colArray}
        </Grid>
      )}
    </Grid>
  );
}

export default UserAccountUpdateComponent;
