import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { postAnonymousContact } from "../../../slice/contact";

const formInput = {
  borderRadius: "10px",
  borderColor: "#e3e3e3",
  height: "50px",
};

const buttonObj = {
  height: "50px",
  width: "150px",
  marginTop: "30px",
  marginLeft: "3px",
  paddingLeft: "15px",
  paddingRight: "15px",
  color: "#ffffff",
  backgroundColor: "#ec5210",
  borderColor: "#ec5210",
  borderRadius: "50px",
};

const ContactFormComponent = ({ page_name }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    contact_message: "",
    contact_solution: "",
    contact_origin_page: "",
  });

  const { contact_name, contact_email, contact_phone, contact_message,  contact_solution, contact_origin_page} = formData;
  const userRef = useRef();

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e, page_name) => {
    e.preventDefault();
    formData["contact_origin_page"] = page_name;
    dispatch(postAnonymousContact(formData));
    setFormData({
      contact_name: "",
      contact_email: "",
      contact_phone: "",
      contact_message: "",
      contact_solution: "",
      contact_origin_page: "",
    });
  };
  return (
    <React.Fragment>
      <Container fluid className="container-xl">
        <Row className="m-2 justify-content-center">
          <Col lg="8" xs="12">
            <div className="custom-form">
              <Form action="#" name="myForm">
                <p id="error-msg"></p>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label className="form-label">Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your name*"
                        style={formInput}
                        ref={userRef}
                        name="contact_name"
                        value={contact_name}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label className="form-label">Solution*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        style={formInput}
                        onChange={(e) => onChange(e)}
                        value={contact_solution}
                        name="contact_solution"
                      >
                        <option >Select Solution</option>
                        <option value="1">
                          Atom DTS Analytics Architecture
                        </option>
                        <option value="2">FreshHUBL</option>
                        <option value="3">PowerBI</option>
                        <option value="4">Cloud Server Hosting</option>
                        <option value="5">App Development & Integration</option>
                        <option value="6">General IT Services</option>
                        <option value="7">Partner Software Support</option>
                        <option value="8">
                          Partner Software Implementations
                        </option>
                        <option value="9">Microsoft reseller</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label className="form-label">
                        Email address* :
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your email"
                        style={formInput}
                        ref={userRef}
                        name="contact_email"
                        value={contact_email}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label className="form-label">
                        Phone number* :
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your number"
                        style={formInput}
                        ref={userRef}
                        name="contact_phone"
                        value={contact_phone}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group className="mb-0" controlId="formBasicName">
                      <Form.Label className="form-label">
                        {" "}
                        Comments :
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        type="text"
                        placeholder="Leave a comment here"
                        style={formInput}
                        ref={userRef}
                        name="contact_message"
                        value={contact_message}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-3 mb-3 justify-content-center">
                  <Col lg="auto" md="auto" sm="auto" xs="auto">
                    <Button
                      // variant="dark"
                      type="submit"
                      size="lg"
                      style={buttonObj}
                      onClick={(e) => onSubmit(e, page_name)}
                      href={"/"}
                    >
                      Send Message
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ContactFormComponent;
