import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedApiCall } from "hubl/utils/helpers";
import { toast } from "react-toastify";

export const fetchUserPageList = createAsyncThunk(
  "planning/user/page/list",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/user/page/list",
        "GET",
        null,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data.pages;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const fetchWineCellarList = createAsyncThunk(
  "planning/wine/cellar/list",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/wine/cellar/list/",
        "GET",
        _,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        const data_sorted = data.sort((a, b) => a.description - b.description);
        const dataMapped = data_sorted.map(({ id, description, capacity }) => ({
          label: description,
          value: id.toString(),
          capacity: capacity,
        }));
        return dataMapped;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchWineProducerList = createAsyncThunk(
  "planning/wine/producer/list",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/wine/producer/list/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        const data_sorted = data.sort((a, b) => a.name - b.name);

        const dataMapped = data_sorted.map(({ id, name }) => ({
          label: name,
          value: id.toString(),
        }));
        return dataMapped;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchWineFarmList = createAsyncThunk(
  "planning/wine/farm/list",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/wine/farm/list/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        const data_sorted = data.sort((a, b) => a.name - b.name);
        const dataMapped = data_sorted.map(({ id, name }) => ({
          label: name,
          value: id.toString(),
        }));
        return dataMapped;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchCultivarList = createAsyncThunk(
  "planning/wine/cultivar/list",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/wine/cultivar/list/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        const data_sorted = data.sort((a, b) => a.name - b.name);
        const dataMapped = data_sorted.map(({ id, description }) => ({
          label: description,
          value: id.toString(),
        }));
        return dataMapped;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchClassList = createAsyncThunk(
  "planning/wine/class/list",
  async (_, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/wine/class/list/",
        "GET",
        _,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        const data_sorted = data.sort((a, b) => a.name - b.name);
        const dataMapped = data_sorted.map(({ id, description }) => ({
          label: description,
          value: id.toString(),
        }));

        return dataMapped;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchBlockList = createAsyncThunk(
  "planning/wine/block/list",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/wine/block/list/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        const data_sorted = data.sort((a, b) => a.name - b.name);
        const dataMapped = data_sorted.map(({ id, name, block_class }) => ({
          label: name,
          value: id.toString(),
          block_class: block_class,
        }));
        return dataMapped;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchCellarEventsList = createAsyncThunk(
  "planning/cellar/event/list",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/cellar/event/list/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const createCellarEvent = createAsyncThunk(
  "planning/cellar/event/create",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/cellar/event/create/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 201) {
        toast.success("Item is geskep", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateCellarEvent = createAsyncThunk(
  "planning/cellar/event/update",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/cellar/event/update/",
        "PUT",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        toast.success("Item is opdateer", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteCellarEvent = createAsyncThunk(
  "planning/cellar/event/delete",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/cellar/event/delete/",
        "DELETE",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        toast.success("Item is verwyder", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createProducerEvent = createAsyncThunk(
  "planning/producer/event/create",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/producer/event/create/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 201) {
        toast.success("Item is geskep", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return data;
      } else {
        toast.error("Item kon nie geskep word nie", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateProducerEvent = createAsyncThunk(
  "planning/producer/event/update",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/producer/event/update/",
        "PUT",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 202) {
        toast.success("Item is op dateer", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return data;
      } else {
        toast.error("Item kon nie opdateer word nie", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);


export const submitProducerEvent = createAsyncThunk(
  "planning/producer/event/submit",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/producer/event/submit/",
        "PUT",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 202) {
        toast.success("Item is op bevestig", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return data;
      } else {
        toast.error("Item kon nie bevestig word nie", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchProducerEventsList = createAsyncThunk(
  "planning/producer/event/list",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/producer/event/list/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteProducerEvent = createAsyncThunk(
  "planning/producer/event/delete",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/producer/event/delete/",
        "DELETE",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        toast.success("Item is verwyder", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const sendProducerCommunication = createAsyncThunk(
  "planning/producer/communication/send",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/producer/communication/send/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 202) {
        toast.success("Kommunikasie is gestuur", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return data;
      } else {
        toast.error(`Kommunikasie nie gestuur nie: ${data.error}`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchProducerEventsLogList = createAsyncThunk(
  "planning/producer/event/log/list",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/producer/event/log/list/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchCommunicationLogList = createAsyncThunk(
  "planning/producer/communication/log/list",
  async (body, thunkAPI) => {
    try {
      const res = await makeAuthenticatedApiCall(
        "planning/producer/communication/log/list/",
        "POST",
        body,
        thunkAPI
      );
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  cellarEvents: [],
  cellarEventsLoading: false,
  createEventsLoading: false,
  updateEventsLoading: false,
  cellarEventNote: null,
  producerEvents: [],
  producerEventsLoading: false,
  createProducerEventsLoading: false,
  updateProducerEventsLoading: false,
  submitProducerEventsLoading: false,
  userPlanningPages: [],
  userPlanningPagesLoading: false,

  cellarEventTons: null,

  producerList: [],
  producerListLoading: false,
  farmList: [],
  farmListLoading: false,
  cellarList: [],
  cellarListLoading: false,
  cultivarList: [],
  cultivarListLoading: false,
  classList: [],
  classListLoading: false,
  blockList: [],
  blockListLoading: false,
  cellar: null,
  producer: null,
  farm: null,
  cultivar: null,
  block: null,
  blockMulti: [],
  goal: [],
  goalMulti: [],
  event_date: null,
  start_time: null,
  end_time: null,
  start_date_range: null,
  end_date_range: null,
  communicationRows: [],
  communicationSendLoading: false,

  producerEventsLog: [],
  producerEventsLogLoading: false,

  communicationLogs: [],
  communicationLogsLoading: false,
  addEventDialogOpen: false,
  validPlanForm: false,
};

const planningSlice = createSlice({
  name: "planning",
  initialState,
  reducers: {
    addEvent: (state, action) => {
      state.cellarEvents.push(action.payload);
    },
    removeEvent: (state, action) => {
      const { id } = action.payload;
      state.cellarEvents = state.cellarEvents.filter(
        (event) => event.id !== id
      );
    },
    selectCellar: (state, action) => {
      state.cellar = action.payload;
    },
    selectProducer: (state, action) => {
      state.producer = action.payload;
    },
    selectFarm: (state, action) => {
      state.farm = action.payload;
    },
    selectCultivar: (state, action) => {
      state.cultivar = action.payload;
    },
    selectBlock: (state, action) => {
      state.block = action.payload;
    },
    selectBlockMulti: (state, action) => {
      state.blockMulti = action.payload;
    },
    selectGoal: (state, action) => {
      state.goal = action.payload;
    },
    selectGoalMulti: (state, action) => {
      state.goalMulti = action.payload;
    },
    selectPlanDate: (state, action) => {
      state.event_date = action.payload;
    },

    selectStartTime: (state, action) => {
      state.start_time = action.payload;
    },

    selectEndTime: (state, action) => {
      state.end_time = action.payload;
    },

    setStartDateRange: (state, action) => {
      state.start_date_range = action.payload;
    },

    setEndDateRange: (state, action) => {
      state.end_date_range = action.payload;
    },

    setCommsRows: (state, action) => {
      state.communicationRows = action.payload;
    },
    setPlanNotes: (state, action) => {
      state.cellarEventNote = action.payload;
    },
    setCellarEventTons: (state, action) => {
      state.cellarEventTons = action.payload;
    },
    setEventDialogOpen: (state, action) => {
      state.addEventDialogOpen = action.payload;
    },
    setValidPlanForm: (state, action) => {
      state.validPlanForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWineCellarList.pending, (state) => {
        state.cellarListLoading = true;
      })
      .addCase(fetchWineCellarList.fulfilled, (state, action) => {
        state.cellarListLoading = false;
        state.cellarList = action.payload;
      })
      .addCase(fetchWineCellarList.rejected, (state) => {
        state.cellarListLoading = false;
      })
      .addCase(fetchCultivarList.pending, (state) => {
        state.cultivarListLoading = true;
      })
      .addCase(fetchWineProducerList.pending, (state) => {
        state.producerListLoading = true;
      })
      .addCase(fetchWineProducerList.fulfilled, (state, action) => {
        state.producerListLoading = false;
        state.producerList = action.payload;
      })
      .addCase(fetchWineProducerList.rejected, (state) => {
        state.producerListLoading = false;
      })
      .addCase(fetchWineFarmList.pending, (state) => {
        state.farmListLoading = true;
      })
      .addCase(fetchWineFarmList.fulfilled, (state, action) => {
        state.farmListLoading = false;
        state.farmList = action.payload;
      })
      .addCase(fetchWineFarmList.rejected, (state) => {
        state.farmListLoading = false;
      })
      .addCase(fetchCultivarList.fulfilled, (state, action) => {
        state.cultivarListLoading = false;
        state.cultivarList = action.payload;
      })
      .addCase(fetchCultivarList.rejected, (state) => {
        state.cultivarListLoading = false;
      })
      .addCase(fetchClassList.pending, (state) => {
        state.classListLoading = true;
      })
      .addCase(fetchClassList.fulfilled, (state, action) => {
        state.classListLoading = false;
        state.classList = action.payload;
      })
      .addCase(fetchClassList.rejected, (state) => {
        state.classListLoading = false;
      })
      .addCase(createCellarEvent.pending, (state) => {
        state.createEventsLoading = true;
      })
      .addCase(createCellarEvent.fulfilled, (state, action) => {
        state.createEventsLoading = false;
      })
      .addCase(createCellarEvent.rejected, (state) => {
        state.createEventsLoading = false;
      })
      .addCase(fetchCellarEventsList.pending, (state) => {
        state.cellarEventsLoading = true;
      })
      .addCase(fetchCellarEventsList.fulfilled, (state, action) => {
        state.cellarEventsLoading = false;
        state.cellarEvents = action.payload;
      })
      .addCase(fetchCellarEventsList.rejected, (state) => {
        state.cellarEventsLoading = false;
      })
      .addCase(fetchBlockList.pending, (state) => {
        state.blockListLoading = true;
      })
      .addCase(fetchBlockList.fulfilled, (state, action) => {
        state.blockListLoading = false;
        state.blockList = action.payload;
      })
      .addCase(fetchBlockList.rejected, (state) => {
        state.blockListLoading = false;
      })
      .addCase(createProducerEvent.pending, (state) => {
        state.createProducerEventsLoading = true;
      })
      .addCase(createProducerEvent.fulfilled, (state, action) => {
        state.createProducerEventsLoading = false;
      })
      .addCase(createProducerEvent.rejected, (state) => {
        state.createProducerEventsLoading = false;
      })
      .addCase(updateProducerEvent.pending, (state) => {
        state.updateProducerEventsLoading = true;
      })
      .addCase(updateProducerEvent.fulfilled, (state, action) => {
        state.updateProducerEventsLoading = false;
      })
      .addCase(updateProducerEvent.rejected, (state) => {
        state.updateProducerEventsLoading = false;
      })

      .addCase(submitProducerEvent.pending, (state) => {
        state.submitProducerEventsLoading = true;
      })
      .addCase(submitProducerEvent.fulfilled, (state, action) => {
        state.submitProducerEventsLoading = false;
      })
      .addCase(submitProducerEvent.rejected, (state) => {
        state.submitProducerEventsLoading = false;
      })
      .addCase(fetchProducerEventsList.pending, (state) => {
        state.producerEventsLoading = true;
      })
      .addCase(fetchProducerEventsList.fulfilled, (state, action) => {
        state.producerEventsLoading = false;
        state.producerEvents = action.payload;
      })
      .addCase(fetchProducerEventsList.rejected, (state) => {
        state.producerEventsLoading = false;
      })
      .addCase(fetchUserPageList.pending, (state) => {
        state.userPlanningPagesLoading = true;
      })
      .addCase(fetchUserPageList.fulfilled, (state, action) => {
        state.userPlanningPagesLoading = false;
        state.userPlanningPages = action.payload;
      })
      .addCase(fetchUserPageList.rejected, (state) => {
        state.userPlanningPagesLoading = false;
      })
      .addCase(updateCellarEvent.pending, (state) => {
        state.updateEventsLoading = true;
      })
      .addCase(updateCellarEvent.fulfilled, (state, action) => {
        state.updateEventsLoading = false;
      })
      .addCase(updateCellarEvent.rejected, (state) => {
        state.updateEventsLoading = false;
      })

      .addCase(deleteCellarEvent.pending, (state) => {
        state.cellarEventsLoading = true;
      })
      .addCase(deleteCellarEvent.fulfilled, (state, action) => {
        state.cellarEventsLoading = false;
      })
      .addCase(deleteCellarEvent.rejected, (state) => {
        state.cellarEventsLoading = false;
      })
      .addCase(deleteProducerEvent.pending, (state) => {
        state.producerEventsLoading = true;
      })
      .addCase(deleteProducerEvent.fulfilled, (state) => {
        state.producerEventsLoading = false;
      })
      .addCase(deleteProducerEvent.rejected, (state) => {
        state.producerEventsLoading = false;
      })

      .addCase(sendProducerCommunication.pending, (state) => {
        state.communicationSendLoading = true;
      })
      .addCase(sendProducerCommunication.fulfilled, (state) => {
        state.communicationSendLoading = false;
      })
      .addCase(sendProducerCommunication.rejected, (state) => {
        state.communicationSendLoading = false;
      })
      .addCase(fetchProducerEventsLogList.pending, (state) => {
        state.producerEventsLogLoading = true;
      })
      .addCase(fetchProducerEventsLogList.fulfilled, (state, action) => {
        state.producerEventsLogLoading = false;
        state.producerEventsLog = action.payload;
      })
      .addCase(fetchProducerEventsLogList.rejected, (state) => {
        state.producerEventsLogLoading = false;
      })
      .addCase(fetchCommunicationLogList.pending, (state) => {
        state.communicationLogsLoading = true;
      })
      .addCase(fetchCommunicationLogList.fulfilled, (state, action) => {
        state.communicationLogsLoading = false;
        state.communicationLogs = action.payload;
      })
      .addCase(fetchCommunicationLogList.rejected, (state) => {
        state.communicationLogsLoading = false;
      });
  },
});

export const {
  addEvent,
  removeEvent,
  selectCellar,
  selectProducer,
  selectFarm,
  selectCultivar,
  selectBlock,
  selectGoal,
  selectPlanDate,
  selectStartTime,
  selectEndTime,
  selectBlockMulti,
  selectGoalMulti,
  setCommsRows,
  setPlanNotes,
  setCellarEventTons,
  setEventDialogOpen,
  setValidPlanForm,
  setStartDateRange,
  setEndDateRange,
} = planningSlice.actions;
export default planningSlice.reducer;
