import { useParams } from "react-router-dom";
import "./blog.scss";
import { Container, Row, Col } from "reactstrap";
import React from "react";
import NavBar from "../../Layout/Navbar";
import FooterAlt from "../../Layout/FooterAlt";
import BackToTop from "../BackToTop";
import Footer from "../../Layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getArticleDetail } from "slice/blog";
import { Spinner } from "react-bootstrap";

const BlogDetails = () => {
  const { articleDetail, articleDetailLoading } = useSelector(
    (state) => state.blogInfo
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    const slug = id;
    dispatch(getArticleDetail(slug));
  }, [id, dispatch]);

  const styleObj = {
    letterSpacing: "3px",
  };

  const parse = require("html-react-parser");

  if (articleDetail && !articleDetailLoading) {
    const blog = articleDetail;
    return (
      <>
        <NavBar />
        <section
          style={{
            backgroundImage: `url(${blog.thumbnail})`,
          }}
          className="bg-home2"
          id="home"
        >
          <div className="bg-overlay"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <div className="text-center text-white">
                  <h3 className="sub-title mb-3">{blog.author}</h3>
                  <br></br>
                  <h3 className="mb-4" style={styleObj}>
                    {blog.title}
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section bg-light" id="contact">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <div className="blog-details">
                  <div className="text-muted mt-2 mb-2 fs-18 p-content">
                    {parse(blog.content)}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <Footer />
        <FooterAlt />
        <BackToTop />
      </>
    );
  } else {
    return (
      <Container className="vh-100">
        <div className="d-flex align-items-center justify-content-center custom-height">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Container>
    );
  }
};

export default BlogDetails;
