import React, { useState, useEffect, Suspense } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Header from "../../components/charts/Header";
import "./recharts.scss";

function RechartsComponent() {
  const [ComponentToRender, setComponentToRender] = useState(null);
  const { userRechartDashboardsLoading, userRechartDashboards } = useSelector((state) => state.rechartDashboards);
  const { section } = useSelector((state) => state.selectedMenuSection);
  const [selectedDashboard, setSelectedDashboard] = useState(null);

  useEffect(() => {
    if (section && !userRechartDashboardsLoading && userRechartDashboards) {
      const dashboard = userRechartDashboards.find((d) => d.id === section);
      setSelectedDashboard(dashboard);
      const LazyComponent = React.lazy(() => import(`./${dashboard.file_name}`));
      setComponentToRender(<LazyComponent />);
    }
  }, [section, userRechartDashboardsLoading, userRechartDashboards]);

  return (
    <div className="rechart-box">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item xs={"auto"} sm={"auto"}>
              <Header title={selectedDashboard ? `Dashboard: ${selectedDashboard.description}` : ""} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Suspense fallback={<div>Loading...</div>}>
            {ComponentToRender}
          </Suspense>
        </Grid>
      </Grid>
    </div>
  );
}

export default RechartsComponent;
