import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Col, Container, Row } from "reactstrap";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import TabsUnstyled from "@mui/base/Tabs";
import TabsListUnstyled from "@mui/base/TabsList";
import TabPanelUnstyled from "@mui/base/TabPanel";
import buttonUnstyledClasses from "@mui/base/Button";
import tabUnstyledClasses from "@mui/base/Tab";
import TabUnstyled from "@mui/base/Tab";

const SectionTeam = () => {
  const [isOpen, setOpen] = useState(false);

  const orange = {
    50: "#ec5210",
    100: "#3b3b42",
    200: "#ffffff",
    300: "#66B2FF",
    400: "#ec5210",
    500: "#efefef",
    600: "#ffffff",
    700: "#0059B2",
    800: "#004C99",
    900: "#003A75",
  };

  const Tab = styled(TabUnstyled)`
    font-family: IBM Plex Sans, sans-serif;
    color: black;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    padding: 12px 16px;
    margin: 6px 6px;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;

    &:hover {
      background-color: ${orange[400]};
    }

    &:focus {
      color: #fff;
      border-radius: 3px;
      outline: 2px solid ${orange[200]};
      outline-offset: 2px;
      background-color: ${orange[50]};
    }

    &.${tabUnstyledClasses.selected} {
      background-color: ${orange[50]};
      color: ${orange[600]};
    }

    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;

  const TabPanel = styled(TabPanelUnstyled)`
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
  `;

  const TabsList = styled(TabsListUnstyled)`
    min-width: 320px;
    background-color: ${orange[500]};
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
  `;

  const styleObj = {
    letterSpacing: "2px",
    fontWeight: "700",
  };

  const styleData = {
    letterSpacing: "1px",
    fontSize: "22px",
    fontWeight: "400",
  };

  return (
    <React.Fragment>
      <section className="bg-home2" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg="8" md="auto">
              <div className="text-center text-white">
                <h3 className="sub-title mb-3">About Us</h3>
                <br></br>
                <h1 className="mb-4" style={styleObj}>
                  <span className="text-primary">D</span>ata.{" "}
                  <span className="text-primary">T</span>echnology.{" "}
                  <span className="text-primary">S</span>ervices.{" "}
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="position-relative">
        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1440"
            height="150"
            preserveAspectRatio="none"
            viewBox="0 0 1440 150"
          >
            <defs>
              <mask id="SvgjsMask1022">
                <rect width="1440" height="250" fill="#ffffff"></rect>
              </mask>
            </defs>
          </svg>
        </div>
      </div>
      <Container>
        <TabsUnstyled className="mt-5" defaultValue={0}>
          <TabsList>
            <Tab>
              {" "}
              <Link
                to="/about"
                style={{
                  color: "#3b3b42",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                }}
              >
                About Us
              </Link>
            </Tab>
            <Tab>
              <Link
                style={{
                  color: "#3b3b42",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                }}
              >
                Meet the Team
              </Link>
            </Tab>
          </TabsList>
        </TabsUnstyled>
      </Container>
      <ModalVideo
        channel="vimeo"
        videoId="287684225"
        autoplay
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default SectionTeam;
