import React, { useState, useEffect } from "react";
import TextFieldsComponent from "../../components/textfield/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import AutocompleteComponent from "../../components/autocomplete/Autocomplete";
import {
  getCustomerList,
  getFilterTypeList,
  postFilterValues,
} from "slice/admin";
import { useSelector, useDispatch } from "react-redux";

function PostFilterValuesComponent() {
  const [selectedCustomer, setCustomer] = useState(null);
  const [selectedfiltertype, setFiltertype] = useState(null);
  const [formData, setFormData] = useState({
    filtertype: "",
    customer: "",
    value: "",
    description: "",
  });

  const { customerList, filterTypeList } = useSelector(
    (state) => state.adminInfo
  );
  const customerListMapped = customerList
    ? customerList.map(({ id, name }) => ({
        label: name,
        value: id.toString(),
      }))
    : [{ label: null, value: null }];

  const filterTypeListMapped = filterTypeList
    ? filterTypeList.map(({ id, name }) => ({
        label: name,
        value: id.toString(),
      }))
    : [{ label: null, value: null }];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCustomerList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFilterTypeList());
  }, [dispatch, selectedCustomer]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    switch (name) {
      case "customer":
        setCustomer(value);
        break;
      default:
        break;
    }
  };

  const handleChangeAutoComplete = (event, values, name) => {
    setFormData({ ...formData, [name]: values.value });
    switch (name) {
      case "customer":
        setCustomer(values);
        break;
      case "filtertype":
        setFiltertype(values);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(postFilterValues(formData)).then((e) => {
      if (!e.error) {
        setFormData({
          filtertype: "",
          customer: "",
          value: "",
          description: "",
        });

        setCustomer(null);
        setFiltertype(null);

        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    });
  };
  return (
      <Grid container spacing={2}>

          <AutocompleteComponent
            title="Customer"
            name="customer"
            options={customerListMapped}
            value={selectedCustomer}
            isMultiple={false}
            onChange={handleChangeAutoComplete}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          />
  
        <Grid item xs={12} sm={10}></Grid>
        {selectedCustomer ? (
          <>
 
              <AutocompleteComponent
                title="Filter Type"
                name="filtertype"
                options={filterTypeListMapped}
                value={selectedfiltertype}
                isMultiple={false}
                onChange={handleChangeAutoComplete}        
                 xs={12}
                sm={12}
                md={12}
                lg={12}
              />
          </>
        ) : (
          <></>
        )}
        {selectedCustomer && selectedfiltertype ? (
          <>

              <TextFieldsComponent
                name="value"
                title="Value(s)"
                placeholder="List of values"
                value={formData.value}
                onChange={handleInputChange}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              />
     

              <TextFieldsComponent
                name="description"
                title="Description(s)"
                placeholder="List of descriptions"
                value={formData.description}
                onChange={handleInputChange}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              />
      
          </>
        ) : (
          <></>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={(e) => handleSubmit(e)}

          >
            Submit
          </Button>
          </Grid>
      </Grid>
  );
}

export default PostFilterValuesComponent;
