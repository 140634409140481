import React from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Card,
  CardGroup,
  CardBody,
  CardTitle,
  CardImg,
  CardText,
} from "reactstrap";

import img01 from "../../assets/images/icons/Home-01.png";
import img02 from "../../assets/images/icons/Home-02.png";
import img03 from "../../assets/images/icons/Home-03.png";

function Services() {
  return (
    <>
      <section className="section bg-home10" id="services">
        <div className="bg-overlay"></div>
        <Container fluid className="container-xl">
          <Row className="justify-content-center">
            <Col lg="auto" md="auto" sm="auto" xs="auto">
              <h2>How We Help You</h2>
              <div className="title-border"></div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col lg="7">
              <p className="text-black-50 fs-18 text-center">
                Atom DTS provides{" "}
                <span className="text-black-80">
                  Data, Technology and Software
                </span>{" "}
                services via our team of specialists. Here’s how our team can
                add value to your business:
              </p>
            </Col>
          </Row>
          <CardGroup>
            <Card body className="m-2 border-0 bg-transparent blog-box ">
              <CardImg top width="90%" alt="Card image cap" src={img01} />
              <CardBody className="text-center">
                <CardTitle tag="h4" className="mb-2">
                  Data
                </CardTitle>
                <div className="title-border"></div>
                <CardText className="mt-4 mb-5">
                  We work with you to transform your data into strategic
                  insights that enable more responsive decision-making.
                </CardText>
                <Link to="/data" className="btn btn-outline-dark">
                  Learn More
                </Link>
              </CardBody>
            </Card>
            <Card body className="m-2 border-0 bg-transparent blog-box ">
              <CardImg alt="Card image cap" src={img02} top width="90%" />
              <CardBody className="text-center">
                <CardTitle tag="h4" className="mb-2">
                  Technology
                </CardTitle>
                <div className="title-border"></div>
                <CardText className="mt-4 mb-5">
                  Our technology unlocks the power of your data! Learn more
                  about our cloud server hosting, application-development and
                  integrations.
                </CardText>
                <Link to="/technology" className="btn btn-outline-dark">
                  Learn More
                </Link>
              </CardBody>
            </Card>
            <Card body className="m-2 border-0 bg-transparent blog-box ">
              <CardImg alt="Card image cap" src={img03} top width="90%" />
              <CardBody className="text-center">
                <CardTitle tag="h4" className="mb-2">
                  Services
                </CardTitle>
                <div className="title-border"></div>
                <CardText className="mt-4 mb-5">
                  Our mission is to solve any issue and unlock true opportunity
                  for you - we pride ourselves on always putting our client’s
                  needs first.
                </CardText>
                <Link to="/services" className="btn btn-outline-dark">
                  Learn More
                </Link>
              </CardBody>
            </Card>
          </CardGroup>
        </Container>
      </section>
    </>
  );
}

export default Services;
