import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Col, Container, Row } from "reactstrap";

const Articles = () => {
  const [isOpen, setOpen] = useState(false);

  const styleObj = {
    letterSpacing: "3px",
  };

  return (
    <React.Fragment>
      <section className="bg-home8" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center text-white">
                <h3 className="sub-title mb-3">CAREER OPPORTUNITIES</h3>
                <br></br>
                <h1 className="mb-4" style={styleObj}>
                  See your career blossom
                </h1>
                <p className="title-desc fs-18 mt-3">
                  We're always on the lookout for like-minded people who want to
                  help propel <br />
                  Atom DTS forward. Apply to join our thriving company today.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="position-relative">
        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1440"
            height="150"
            preserveAspectRatio="none"
            viewBox="0 0 1440 150"
          >
            <defs>
              <mask id="SvgjsMask1022">
                <rect width="1440" height="250" fill="#ffffff"></rect>
              </mask>
            </defs>
          </svg>
        </div>
      </div>
      <ModalVideo
        channel="vimeo"
        videoId="287684225"
        autoplay
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default Articles;
