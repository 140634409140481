import { useSelector, useDispatch } from "react-redux";
import { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "./hubl/pages/dashboard/Dashboard";
import LoginPage from "./hubl/pages/login/Login";
import Register from "./hubl/pages/register/Register";

import Home from "./website/Layout/Index.js";
import Articles from "./website/pages/articles/Articles";
import Vacancies from "website/pages/vacancies/Vacancies";
import VacancyDetails from "./website/components/blog/VacanciesDetails";
import BlogDetails from "./website/components/blog/BlogDetails";
import NotFound from "./website/components/blog/NotFound";
import BlogHome from "website/components/blog/blog";
import Team from "./website/pages/team/Team";

import PrivateRoute from "./hubl/utils/PrivateRoute";
import ResetPassword from "./hubl/pages/password/ResetPassword";
import ResetPasswordConfirm from "./hubl/pages/password/ConfirmResetPassword";

import ContactPage from "./website/pages/contact/Contact";
import CircularIndeterminate from "website/pages/CircularLoader";

import "./style/dark.scss";
import "react-toastify/dist/ReactToastify.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getArticleList } from "slice/blog";
import { getVacancyList } from "slice/vacancy";


// import SandboxComponent from "./pages/sandbox/Sandbox";
// import List from "./pages/list/List";
// import Single from "./pages/single/Single";


const About = lazy(() => import("./website/pages/about/About"));
const AtomPage = lazy(() => import("website/pages/atomabout/AtomPage"));
const BiPage = lazy(() => import("website/pages/powerBiAbout/BiPage"));
const Data = lazy(() => import("./website/pages/data/Data"));
const Solutions = lazy(() => import("./website/pages/solutions/Solutions"));
const ServicesMain = lazy(() => import("website/pages/services/ServicesMain"));
const TechnologyMain = lazy(() => import("website/pages/technology/TechnologyMain"));

const App = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode);
  useEffect(() => {
    dispatch(getArticleList());
    dispatch(getVacancyList());
  }, [dispatch]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Atom DTS - Provides Data, Technology and Software services via our
          team of specialists.{" "}
        </title>
        <meta
          name="description"
          content="Atom DTS delivers world-class application support, modern Business Intelligence solutions and wide ranging IT services through a one-stop delivery channel to our clients in the Fresh Produce industry and beyond."
        />
        ;
      </Helmet>
      <div className={darkMode ? "app dark" : "app"}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route
                path="about"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <About />
                  </Suspense>
                }
              />
              <Route
                path="atomabout"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <AtomPage />
                  </Suspense>
                }
              />

              {/* <Route path="sandbox" element={<SandboxComponent />} /> */}

              <Route path="articles" element={<Articles />} />
              <Route path="vacancies" element={<Vacancies />} />
              <Route path="/vacancy/:id" element={<VacancyDetails />} />

              <Route
                path="powerbi"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <BiPage />
                  </Suspense>
                }
              />
              <Route
                path="data"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <Data />
                  </Suspense>
                }
              />
              <Route path="contact" element={<ContactPage />} />
              <Route path="team" element={<Team />} />
              <Route
                path="solutions"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <Solutions />
                  </Suspense>
                }
              />
              <Route path="login" element={<LoginPage />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route
                path="password/reset/confirm/:uid/:token"
                element={<ResetPasswordConfirm />}
              />
              {/* <Route path='activate/:uid/:token' element={Activate} /> */}
              <Route
                path="services"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <ServicesMain />
                  </Suspense>
                }
              />
              <Route
                path="technology"
                element={
                  <Suspense fallback={<CircularIndeterminate />}>
                    <TechnologyMain />
                  </Suspense>
                }
              />
              <Route path="post" element={<BlogHome />} />
              {/* <Route path="blog/create" element={<Create />} /> */}
              <Route path="/blog/:id" element={<BlogDetails />} />
              <Route path="*" element={<NotFound />} />
              <Route element={<PrivateRoute />}>
                <Route path="hubl" element={<Dashboard />} />
              </Route>
              {/* <Route path="landing" element={<TicketAccordian />} /> */}
              <Route path="register" element={<Register />} />
              {/* <Route path="users">
                <Route index element={<List />} />
                <Route path=":userId" element={<Single />} />
                <Route
                  path="new"
                  element={<New inputs={userInputs} title="Add New User" />}
                />
              </Route>
              <Route path="products">
                <Route index element={<List />} />
                <Route path=":productId" element={<Single />} />
                <Route
                  path="new"
                  element={
                    <New inputs={productInputs} title="Add New Product" />
                  }
                />
              </Route> */}
            </Route>
          </Routes>
        </Router>
      </div>
    </HelmetProvider>
  );
};

function wait(time) {
  return new Promise((reslove) => {
    setTimeout(reslove, time);
  });
}

export default App;
