import React from "react";
import { Col, Container, Row } from "reactstrap";
import ContactFormComponent from "../components/contact/ContactForm";

const Card = (props) => {
  return (
    <React.Fragment>
      <Col lg="auto" md="auto">
        <div className="contact-info text-center mt-5">
          <div className="icon">
            <i className={props.iconClass}></i>
          </div>
          <div className="mt-4 pt-2">
            <h6 className="fs-17">{props.title}</h6>
            {props.isChildItem.map((item, key) => (
              <p className="text-muted mb-0" key={key}>
                {item}
              </p>
            ))}
          </div>
        </div>
      </Col>
      {/* <Col lg="4" md="6" xs="12"></Col> */}
    </React.Fragment>
  );
};

// Contact Data

const ContactData = [
  {
    title: "Phone",
    iconClass: "mdi mdi-phone text-primary h4",
    isChildItem: ["+27 (21) 852 9521"],
  },
  {
    title: "Main Office",
    iconClass: "mdi mdi-map-marker text-primary h4",
    isChildItem: [
      "Oakmont Building",
      "Somerset Links Office Park",
      "De Beers Ave, Somerset West, 7130",
    ],
  },
  {
    title: "Contact",
    iconClass: "mdi mdi-email text-primary h4",
    isChildItem: ["services@atomdts.co.za"],
  },
];

const Contact = () => {
  return (
    <React.Fragment>
      <section className="section bg-home10" id="contact">
        <div className="bg-overlay"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg="auto">
              <div className="text-center mb-4">
                <h2 className="">Get in Touch</h2>
                <div className="title-border mt-3"></div>
                <p className="title-desc fs-18 text-muted mt-3">
                  Get in touch to find our more about our{" "}
                  <span className="text-black-80">
                    Data, Technology or Software
                  </span>{" "}
                  solutions.
                </p>
              </div>
            </Col>
          </Row>

          {/*  */}

          {/* <Row className="justify-content-center">
           
            {ContactData.map((value, idx) => (
              <Card
                key={idx}
                title={value.title}
                iconClass={value.iconClass}
                isChildItem={value.isChildItem}
              />
            ))}
           
          </Row> */}

          <Row className="justify-content-center mb-3">
            <Col lg="2" md="6" xs="12">
              <div className="contact-info text-center mt-2">
                <div className="icon">
                  <i className="mdi mdi-phone text-primary h4"></i>
                </div>
                <div className="mt-4 pt-2">
                  <h6 className="fs-17">Phone</h6>
                  {/* <p className="text-muted mb-0">+27 (21) 852 9521</p> */}
                  <a className="text-muted mb-0" href="tel:+27218529521">
                    +27 (21) 852 9521
                  </a>
                </div>
              </div>
            </Col>
            <Col lg="4" md="auto">
              <div className="contact-info text-center mt-2">
                <div className="icon">
                  <i className="mdi mdi-map-marker text-primary h4"></i>
                </div>
                <div className="mt-4 pt-2">
                  <h6 className="fs-17">Main Office</h6>
                  <a
                    className="text-muted mb-0"
                    href="https://goo.gl/maps/Zp2B1ByKCs9wskJM9"
                    target="_blank"
                  >
                    Oakmont Building, Somerset Links Office Park,De Beers Ave,
                    Somerset West, 7130
                  </a>
                </div>
              </div>
            </Col>
            <Col lg="2" md="auto">
              <div className="contact-info text-center mt-2">
                <div className="icon">
                  <i className="mdi mdi-email text-primary h4"></i>
                </div>
                <div className="mt-4 pt-2">
                  <h6 className="fs-17">Contact</h6>
                  <a
                    className="text-muted mb-0"
                    href="mailto:services@atomdts.co.za"
                  >
                    services@atomdts.co.za
                  </a>
                </div>
              </div>
            </Col>
          </Row>

          <ContactFormComponent page_name="Home Page" />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
