import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
  getCustomerList,
  getCustomerActiveReports,
  postReportGroup,
} from "slice/admin";
import { useSelector, useDispatch } from "react-redux";
import { BulletList } from "react-content-loader";
import TextFieldsComponent from "../../components/textfield/TextField";
import AutocompleteComponent from "../../components/autocomplete/Autocomplete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

function PostReportGroupComponent() {
  const [selectedCustomer, setCustomer] = useState(null);
  const [checked, setChecked] = useState("");

  const { customerList, customerActiveReports, customerActiveReportsLoading } =
    useSelector((state) => state.adminInfo);
  const [formData, setFormData] = useState({
    customer: "",
    name: "",
    report: [],
  });

  const customerListMapped = customerList
    ? customerList.map(({ id, name }) => ({
        label: name,
        value: id.toString(),
      }))
    : [{ label: null, value: null }];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCustomerList());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCustomer) {
      dispatch(getCustomerActiveReports(selectedCustomer.value));
    }
  }, [dispatch, selectedCustomer]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const newReport = [...formData.report];
    if (currentIndex === -1) {
      newChecked.push(value);
      newReport.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      newReport.splice(currentIndex, 1);
    }
    setFormData({ ...formData, report: newReport });
    setChecked(newChecked);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChangeAutoComplete = (event, values, name) => {
    setFormData({ ...formData, [name]: values.value });
    switch (name) {
      case "customer":
        setCustomer(values);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(postReportGroup(formData)).then((e) => {
      if (!e.error) {
        setFormData({
          customer: "",
          name: "",
          report: [],
        });

        setCustomer(null);

        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    });
  };

  const colArray = [];
  for (let i = 0; i < 2; i++) {
    colArray.push(
      <Grid item xs={12} sm={12} key={i}>
        <BulletList />
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <TextFieldsComponent
        title="Report Group Name"
        name="name"
        placeholder="new report group name"
        value={formData.name}
        onChange={handleInputChange}
        xs={12}
        sm={12}
        md={12}
        lg={12}
      />

      <AutocompleteComponent
        title="Customer"
        name="customer"
        options={customerListMapped}
        value={selectedCustomer}
        isMultiple={false}
        onChange={handleChangeAutoComplete}
        xs={12}
        sm={12}
        md={12}
        lg={12}
      />

      {selectedCustomer &&
      !customerActiveReportsLoading &&
      customerActiveReports ? (
        <List>
          {customerActiveReports.map(({ report_id, name, is_active }) => (
                <Grid container spacing={2}>
            <ListItem key={`report_list_${report_id}`}>
            <Grid item xs={"auto"} sm={"auto"} md={"auto"} lg={"auto"}>
              <ListItemButton
                role={undefined}
                onClick={handleToggle(report_id)}
                dense
                style={{ minWidth: "auto", paddingRight: "0px"}}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(report_id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={`aria-labelledby-${report_id}`}
                  />
                </ListItemIcon>
              </ListItemButton>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
              <ListItemText
                id={`switch-list-label-${report_id}`}
                primary={name}
                style={{ marginLeft: "8px" }} // Adjust margin as needed for alignment
              />   </Grid>
            </ListItem>
            </Grid>
          ))}
        </List>
      ) : (
        <>
          {customerActiveReportsLoading ? (
            <Grid item xs={12} sm={12}>
              {colArray}
            </Grid>
          ) : (
            <></>
          )}
        </>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={(e) => handleSubmit(e)}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}

export default PostReportGroupComponent;
