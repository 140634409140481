export const TICKET_PRIORITY_SORT = {
  "Mission Critical (max 6 hrs)": 1,
  "High (max 2 days)": 2,
  "Within 5 days": 3,
  "Medium (max 5 days)": 4,
  Medium: 5,
  "Within five days (120 hours)": 6,
  "Within 2 weeks": 7,
  "Low (min 2 weeks)": 8,
  "Long-term (4 to 6 weeks)": 9,
  "Within 6 weeks": 10,
  "Modification - P1": 11,
  "Modification - P2": 12,
  "Modification - P3": 13,
  "Modification - P4": 14,
  "Partner Dependent": 15,
};

export const ATOM_ACCOUNT_NAMES = [
  "ATOM DTS Services",
  "Atom DTS Implementations",
  "ATOM DTS Data Solutions",
  "ATOM DTS Technology Solutions",
  "ATOM DTS Quick Tickets",
];

export const PROPHET_ACCOUNT_NAMES = ["Prophet"];

export const CLIENT_STATUSSES = [
  "Customer to Approve Estimate",
  "Customer to Approve Specification",
  "In Customer Testing",
];
