import React, { useEffect } from "react";

import NavBar from "../../Layout/Navbar";
import SectionTeam from "./Section";
import TeamGeneral from "./General";
import TeamServices from "./Services";
import TeamData from "./Data";
import TeamTech from "./Technology";
import FooterAlt from "../../Layout/FooterAlt";
import BackToTop from "../../components/BackToTop";
import Footer from "../../Layout/Footer";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

export default function TeamPage() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    let attempts = 0;
    const maxAttempts = 10;

    const scrollToElement = () => {
      if (hash) {
        let element = document.getElementById(hash.replace("#", ""));
        console.log("Element:", element); // Debugging
        console.log("Hash:", hash); // Debugging
        if (element) {
          const yOffset = -100;
          const y =
            element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
          return true;
        }
      } else {
        window.scrollTo(0, 0);
        return true;
      }
      return false;
    };

    const tryScrolling = () => {
      if (!scrollToElement() && attempts < maxAttempts) {
        attempts++;
        setTimeout(tryScrolling, 2500);
      }
    };

    if (document.readyState === "complete") {
      tryScrolling();
    } else {
      window.addEventListener("load", tryScrolling);
      return () => window.removeEventListener("load", tryScrolling);
    }
  }, [pathname, hash]);

  const darkMode = useSelector((state) => state.darkMode);
  return (
    <React.Fragment>
      <Helmet>
        <title>Team</title>
      </Helmet>
      <NavBar isDark={darkMode} />
      <SectionTeam />
      <TeamGeneral id="general" />
      <TeamServices id="services" />
      <TeamData id="data" />
      <TeamTech id="technology" />
      <Footer />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
}
