import React from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Card,
  CardGroup,
  CardBody,
  CardTitle,
  CardImg,
  CardText,
} from "reactstrap";

import HUBL from "../../assets/images/partners/AtomHUBL.png";
import powerbi from "../../assets/images/feature/powerbidash.jpg";
import cloudserver from "../../assets/images/partners/cloudserverhost.jpg";

const Process = () => {
  return (
    <>
      <section className="section bg-home11" id="services">
        <div className="bg-overlay"></div>
        <Container fluid className="container-xl">
          <Row className="justify-content-center">
            <Col lg="auto" md="auto" sm="auto" xs="auto">
              <h2 className="">Our Featured Solutions</h2>
              <div className="title-border"></div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col lg="7">
              <p className="text-black-50 fs-16 text-center">
                {/* We craft digital, graphic and dimensional thinking, to create
                category leading brand experiences that have meaning and add a
                value. */}
              </p>
            </Col>
          </Row>
          <CardGroup className="mb-5">
            <Card
              style={{ borderRadius: 8 }}
              body
              className="m-3 border-0 shadow-sm p-3 mb-5 bg-white rounded blog-box box-shadow"
            >
              <CardImg
                top
                width="90%"
                height="205px"
                alt="Card image cap"
                src={HUBL}
                loading="lazy"
                className="img-fluid"
              />
              <CardBody className="text-center">
                <CardTitle tag="h5" className="mb-2">
                  FreshHUBL
                </CardTitle>
                <div className="title-border"></div>
                <CardText className="mt-4 mb-4">
                  Unlock the true potential of live data, enabling you to make
                  decisions surrounding profitability far quicker. Fresh HUBL -
                  powered by Atom - is a web-based data and analytics portal,
                  that can be accessed from anywhere.
                </CardText>
                <Link to="/atomabout" className="btn btn-dark">
                  Learn More
                </Link>
              </CardBody>
            </Card>
            <Card
              style={{ borderRadius: 8 }}
              body
              className="m-3 border-0 shadow-sm p-3 mb-5 bg-white rounded blog-box box-shadow"
            >
              <CardImg
                alt="Card image cap"
                src={powerbi}
                top
                width="90%"
                height="205px"
                className="img-fluid"
                loading="lazy"
              />
              <CardBody className="text-center">
                <CardTitle tag="h5" className="mb-2">
                  Power BI Analytics
                </CardTitle>
                <div className="title-border"></div>
                <CardText className="mt-4 mb-5">
                  We work with you to unpack what you really need and deliver on
                  that promise. Our established team of Power BI experts deliver
                  custom solutions across the main components of Power BI
                  technologies.
                </CardText>
                <Link to="/powerbi" className="btn btn-dark">
                  Learn More
                </Link>
              </CardBody>
            </Card>
            <Card
              style={{ borderRadius: 8 }}
              body
              className="m-3 border-0 shadow-sm p-3 mb-5 bg-white rounded blog-box box-shadow"
            >
              <CardImg
                alt="Card image cap"
                src={cloudserver}
                top
                width="90%"
                height="205px"
                className="img-fluid"
                loading="lazy"
              />
              <CardBody className="text-center">
                <CardTitle tag="h5" className="mb-2">
                  Cloud Server Hosting
                </CardTitle>
                <div className="title-border"></div>
                <CardText className="mt-4 mb-5">
                  We pride ourselves on prioritising our client’s needs and
                  always putting you first. Our team of technology specialists
                  provide cloud server hosting, application development and
                  integrations.
                </CardText>
                <Link to="/technology" className="btn btn-dark">
                  Learn More
                </Link>
              </CardBody>
            </Card>
          </CardGroup>
        </Container>
      </section>
    </>
  );
};

export default Process;
