import React from "react";
import NavBar from "../../Layout/Navbar";
import SectionAbout from "./Section";
import FooterAlt from "../../Layout/FooterAlt";
import BackToTop from "../../components/BackToTop";
import Footer from "../../Layout/Footer";
import ArticlesCont from "../ArticlesCont";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const Articles = () => {
  const darkMode = useSelector((state) => state.darkMode);

  return (
    <React.Fragment>
      <Helmet>
        <title>Articles</title>
      </Helmet>
      <NavBar isDark={darkMode} />
      <SectionAbout />
      <ArticlesCont />
      <Footer />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};

export default Articles;
