import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Header from "../../components/charts/Header";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllNotifications,
  setUnreadCount,
  addNotification,
  markAsRead,
} from "../../../slice/notificationSlice";
import "./notifications.scss";
import { formatDate } from "../../utils/helpers";
import IconButton from "@mui/material/IconButton";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";

const ReleaseNoteComponent = () => {
  const dispatch = useDispatch();
  const { allNotifications, unreadCount } = useSelector(
    (state) => state.notifications
  );
  const [allNotificationsSort, setAllNotificationsSort] = useState([]);
  let { user } = useSelector((state) => state.userInfo);
  const [expanded, setExpanded] = useState(`panel0`);
  // WebSocket setup to listen to new notifications.
  useEffect(() => {
    const channel_name = "AllUsers"; // Should be fetched based on the user
    const ws = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET}/notifications/${channel_name}/`
    );

    // dispatch(fetchUnreadNotifications()).then((e) => {
    //   console.log(e);
    // });
    dispatch(
      fetchAllNotifications({
        channel_name: channel_name,
        type_name: "Release Notes",
      })
    );
    ws.onmessage = (event) => {
      // Assume the WebSocket sends notifications as stringified JSON
      const notification = JSON.parse(event.data).content;
      dispatch(addNotification(notification));
    };
  }, []);

  useEffect(() => {
    if (user && allNotifications) {
      dispatch(
        setUnreadCount(
          allNotifications.filter((n) => !n.read.includes(user.id)).length
        )
      );
    }
    if (allNotifications) {
      setAllNotificationsSort(
        [...allNotifications].sort((a, b) => {
          const dateA = new Date(a.date_created);
          const dateB = new Date(b.date_created);
          return dateB - dateA;
        })
      );
    }
  }, [allNotifications]);

  const handleResetAll = () => {
    const unreadNotifications = allNotifications.filter(
      (n) => !n.read.includes(user.id)
    );
    for (let index = 0; index < unreadNotifications.length; index++) {
      dispatch(
        markAsRead({
          notificationId: unreadNotifications[index].id,
          userId: user.id,
        })
      );

    }
  };
  const handleChange = (panel, id) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    dispatch(markAsRead({ notificationId: id, userId: user.id }));
  };
  return (
    <div className="notification-container">
      {allNotifications && user ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2} sx={{ mb: "20px" }}>
              <Grid item xs={12} sm={12}>
                <Header
                  title={`What's new on HUBL`}
                  subtitle={`Latest updates and features`}
                />
              </Grid>
              {/* <Grid item xs={6} sm={6} container justifyContent="flex-end">
               */}
              <Grid item xs={12} sm={12}>
                {unreadCount === 0 ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <MarkEmailReadOutlinedIcon sx={{ mr: "2px" }} />
                    <Typography
                      display={"inline"}
                      fontSize={"10px"}
                      align="center"
                    >
                      All read
                    </Typography>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleResetAll}
                  >
                    <MarkunreadOutlinedIcon
                      sx={{ mr: "2px" }}
                      onClick={handleResetAll}
                    />
                    <Typography
                      display={"inline"}
                      fontSize={"10px"}
                      align="center"
                    >
                      Mark all as read
                    </Typography>
                  </div>
                )}
              </Grid>

              {/* <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    height: "100%",
                  }}
                ></Box> */}
              {/* </Grid> */}
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                {allNotificationsSort.map((notification, index) => (
                  <Accordion
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`, notification.id)}
                    key={index}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}bh-content`}
                      id={`panel${index}bh-header`}
                      style={{ paddingRight: "10px", borderRadius: "8px" }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <Typography
                            sx={{ fontWeight: "bold", flexShrink: 0 }}
                          >
                            {notification.title}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            paddingTop: "0px !important",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div className="note-type" >Release Note</div>
                            <div className="note-date">
                              {formatDate(notification.date_created)}
                            </div>
                            <IconButton edge="end" aria-label="Mark as Read">
                              {notification.read.includes(user.id) ? (
                                <CheckCircleOutlineIcon
                                  style={{ color: "green" }}
                                />
                              ) : (
                                <RadioButtonUncheckedIcon color="primary" />
                              )}
                            </IconButton>
                          </div>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ReactMarkdown>{notification.content}</ReactMarkdown>
                    </AccordionDetails>
                  </Accordion>
                ))}{" "}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}></Grid>
      )}
    </div>
  );
};

export default ReleaseNoteComponent;
