// import DashboardIcon from "@mui/icons-material/Dashboard";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { selectDashboardSection } from "slice/dashboardSection";
import { selectMenuItem } from "slice/menuSection";

const MenuAccordian = ({ sectionName, pagedict, itemTypes }) => {
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();
  const { section } = useSelector((state) => state.dashboardSection);

  const toggleTitle = (i) => {
    setSelected(i);
  };

  const onClickLink = (key) => {
    dispatch(selectDashboardSection(itemTypes));
    dispatch(selectMenuItem(key));
    window.scrollTo({ top: 0, behavior: "smooth" });
    toggleTitle(key);
  };

  return (
    <Accordion
      disableGutters
      elevation={0}
      defaultExpanded={true}
      className="section"
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="accordian-icon" />}
        key={sectionName}
        className="title"
        sx={{
          margin: 0,
        }}
      >
        {sectionName.toUpperCase()}
      </AccordionSummary>
      <AccordionDetails className="details">
        {pagedict.map(({ name, description }) => (
          <li
            className={`item ${
              selected === name && itemTypes === section ? "item-active" : ""
            }`}
            onClick={() => onClickLink(name)}
            key={`${section}_${name}`}
            id={`${section}_${name}`}
          >
            {itemTypes === "Admin" ? (
              <AccountCircleOutlinedIcon className="icon" />
            ) : (
              <DashboardIcon className="icon" />
            )}
            <span>
              <div
                className={`item ${
                  selected === name && itemTypes === section
                    ? "item-active"
                    : ""
                }`}
                onClick={() => onClickLink(name)}
                key={`${name}`}
              >
                {description}
              </div>
            </span>
          </li>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default MenuAccordian;
