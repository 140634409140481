import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { tokens } from "../../theme";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "../../theme";
import LineChartComponent from "../../components/charts/LineChart";
import BarChartComponent from "../../components/charts/BarChart";
import ChartCard from "../../components/cards/ChartCard";
import Grid from "@mui/material/Grid";
import BasicKpiCardComponent from "../../components/cards/BasicKpiCard";
import AutocompleteComponent from "../../components/autocomplete/Autocomplete";
import {
  fetchUserMonitorReportClickByDates,
  fetchUserMonitorSummaryCustomerUserNames,
  fetchUserMonitorSummaryCustomerUsers,
  fetchUserMonitorUserLogins,
  fetchUserMonitorCustomerList,
  fetchUserMonitorUserList,
  fetchUserMonitorUserTypeList,
  fetchUserMonitorSummaryUserLoginSummary,
  fetchUserMonitorReportClickByReport,
} from "slice/userMonitorSlice";
import HorizontalBarChartComponent from "../../components/charts/BarChartHorizontal";
import BarGraphLoader from "hubl/components/loaders/BarGraphLoader";
import SwitchButtonGroup from "hubl/components/switch/Toggle";
import DisplayTableComponent from "../../components/charts/DisplayTable";
import { v4 as uuidv4 } from "uuid";
import { formatDate } from "../../utils/helpers";
import { set } from "lodash";

const UserMonitorComponent = () => {
  const darkMode = useSelector((state) => state.darkMode);
  const {
    customerUsers,
    customerUsersLoading,
    customerUserNames,
    customerUserNamesLoading,
    userLoginSummary,
    userLoginSummaryLoading,
    userLogins,
    userLoginsLoading,
    clicksByReportLoading,
    clicksByReport,
    clicksByDate,
    clicksByDateLoading,
    customerList,
    customerListLoading,
    userList,
    userListLoading,
    userTypeList,
    userTypeListLoading,
  } = useSelector((state) => state.userMonitor);
  const theme = createTheme(themeSettings(darkMode ? "dark" : "light"));
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const [activeBarIndexCustomer, setActiveBarIndexCustomer] = useState(null);
  const [activeBarIndexUser, setActiveBarIndexUser] = useState(null);
  const [activeBarIndexReport, setActiveBarIndexReport] = useState(null);
  const [visualSwitch, setVisualSwitch] = useState("graph");
  const [activeCellTable1, setActiveCellTable1] = useState(null);
  const [activeCellTable2, setActiveCellTable2] = useState(null);
  const [activeCellTable3, setActiveCellTable3] = useState(null);
  const [activeCellTable4, setActiveCellTable4] = useState(null);

  const [filterOptions, setFilterOptions] = useState({
    customer: [],
    user: [],
    usertype: [],
    report: [],
  });

  const handelCrossFilter = (data, visual) => {
    if (!data) {
      setActiveBarIndexCustomer(null);
      setActiveBarIndexUser(null);
      setActiveBarIndexReport(null);
      dispatch(fetchUserMonitorReportClickByReport(filterOptions));
      dispatch(fetchUserMonitorSummaryCustomerUsers(filterOptions));
      dispatch(fetchUserMonitorReportClickByDates(filterOptions));
      dispatch(fetchUserMonitorUserLogins(filterOptions));
      dispatch(fetchUserMonitorCustomerList(filterOptions));
      dispatch(fetchUserMonitorUserList(filterOptions));
      dispatch(fetchUserMonitorSummaryCustomerUserNames(filterOptions));

      setActiveCellTable1(null);
      setActiveCellTable2(null);
      setActiveCellTable3(null);
      setActiveCellTable4(null);
      dispatch(fetchUserMonitorSummaryCustomerUserNames(filterOptions));
      dispatch(fetchUserMonitorSummaryUserLoginSummary(filterOptions));

      return;
    } else {
      let selected_customer_id;
      let selected_user_id;
      let newFilterOptions;
      let selected_report_id;
      switch (visual) {
        case "customer_bar":
          selected_customer_id = customerList.find(
            (customer) => customer.customer_name === data.activeLabel
          ).customer_field;
          newFilterOptions = {
            ...filterOptions,
            customer: [...filterOptions.customer, selected_customer_id],
          };
          dispatch(fetchUserMonitorReportClickByReport(newFilterOptions));
          dispatch(fetchUserMonitorReportClickByDates(newFilterOptions));
          dispatch(fetchUserMonitorUserLogins(newFilterOptions));
          dispatch(fetchUserMonitorUserList(newFilterOptions));

          setActiveBarIndexCustomer(data.activeLabel);
          break;
        case "user_bar":
          selected_user_id = userList.find(
            (user) =>
              user.full_name ===
              `${data.activePayload[0].payload.first_name} ${data.activePayload[0].payload.last_name}`
          ).user_id;
          newFilterOptions = {
            ...filterOptions,
            user: [...filterOptions.user, selected_user_id],
          };
          dispatch(fetchUserMonitorSummaryCustomerUsers(newFilterOptions));
          dispatch(fetchUserMonitorReportClickByReport(newFilterOptions));
          dispatch(fetchUserMonitorReportClickByDates(newFilterOptions));
          setActiveBarIndexUser(data.activeLabel);
          break;
        case "report_bar":
          selected_report_id = clicksByReport.find(
            (report) =>
              report.report_name === data.activePayload[0].payload.report_name
          ).report_id_val;

          newFilterOptions = {
            ...filterOptions,
            report: [...filterOptions.report, selected_report_id],
          };
          dispatch(fetchUserMonitorReportClickByDates(newFilterOptions));
          setActiveBarIndexReport(data.activeLabel);
          break;
        case "table1":
          selected_customer_id = customerList.find(
            (customer) => customer.customer_name === data.row.customer_name
          ).customer_field;
          newFilterOptions = {
            ...filterOptions,
            customer: [...filterOptions.customer, selected_customer_id],
          };
          dispatch(fetchUserMonitorSummaryCustomerUserNames(newFilterOptions));
          dispatch(fetchUserMonitorSummaryUserLoginSummary(newFilterOptions));
          dispatch(fetchUserMonitorUserList(newFilterOptions));
          dispatch(fetchUserMonitorCustomerList(newFilterOptions));
          setActiveCellTable1({
            id: data.row.customer_name,
            field: data.field,
          });
          break;
        case "table2":
          setActiveCellTable2({ id: data.id, field: data.field });
          selected_user_id = userList.find(
            (user) => user.full_name === data.row.full_name
          ).user_id;
          newFilterOptions = {
            ...filterOptions,
            user: [...filterOptions.user, selected_user_id],
          };
          dispatch(fetchUserMonitorSummaryCustomerUsers(newFilterOptions));
          dispatch(fetchUserMonitorSummaryUserLoginSummary(newFilterOptions));
          setActiveCellTable2({ id: data.row.full_name, field: data.field });
          break;
        case "table3":
          setActiveCellTable3({ id: data.id, field: data.field });

          break;
        case "table4":
          setActiveCellTable4({ id: data.id, field: data.field });

          break;
        default:
          console.log("Nothing");
          break;
      }
    }
  };
  // CUSTOMER BAR
  const handleCustomerBarClick = (data, index) => {
    handelCrossFilter(data, "customer_bar");
  };
  const handleUserBarClick = (data, index) => {
    handelCrossFilter(data, "user_bar");
  };

  const handleReportBarClick = (data, index) => {
    handelCrossFilter(data, "report_bar");
  };

  useEffect(() => {
    const customerIds = filterOptions.customer.map(
      (customer) => customer.value
    );

    const userIds = filterOptions.user.map((user) => user.value);
    const userTypeIds = filterOptions.usertype.map(
      (usertype) => usertype.value
    );

    const filterPayload = {
      customer: customerIds,
      user: userIds,
      usertype: userTypeIds,
    };

    dispatch(fetchUserMonitorCustomerList(filterPayload));
    dispatch(fetchUserMonitorUserList(filterPayload));
    dispatch(fetchUserMonitorUserTypeList(filterPayload));
  }, [dispatch]);

  // TABLE 1:1
  const getCellClassT1 = (params) => {

    return activeCellTable1 && activeCellTable1.id === params.row.customer_name
      ? "clicked"
      : "";
  };

  const table1_data = customerUsers
    ? [...customerUsers].sort((a, b) => b.user_count - a.user_count)
    : [];
  const data_table1_ids = table1_data.map((row) => ({ ...row, id: uuidv4() }));

  const table1_columns = [
    {
      field: "customer_name",
      headerName: "Customer",
      width: 150,
      cellClassName: getCellClassT1,
    },
    {
      field: "user_count",
      headerName: "Total Users",
      width: 80,
      cellClassName: getCellClassT1,
    },
  ];
  const handleCellClickTable1 = (params, event) => {
    if (activeCellTable1 && activeCellTable1.id === params.row.customer_name) {
      handelCrossFilter(null, "table1");
    } else {
      handelCrossFilter(params, "table1");
    }
  };

  // TABLE 1:2
  const getCellClassT2 = (params) => {

    return activeCellTable2 && activeCellTable2.id === params.row.full_name
      ? "clicked"
      : "";
  };

  const table2_data = customerUserNames
    ? [...customerUserNames].sort((a, b) => b.customer_name - a.customer_name)
    : [];
  const data_table2_ids = table2_data.map((row) => ({ ...row, id: uuidv4() }));

  const table2_columns = [
    {
      field: "customer_name",
      headerName: "Customer",
      width: 150,
      cellClassName: getCellClassT2,
    },
    {
      field: "full_name",
      headerName: "User",
      width: 200,
      cellClassName: getCellClassT2,
    },
  ];
  const handleCellClickTable2 = (params, event) => {
    handelCrossFilter(params, "table2");
  };

  const handleOutsideClick = () => {
    handelCrossFilter(null, "none");
  };

  // TABLE 1:3
  const table3_data = userLoginSummary
    ? [...userLoginSummary].sort((a, b) => b.date_format - a.date_format)
    : [];
  const data_table3_id = table3_data.map((row) => ({ ...row, id: uuidv4() }));
  const data_table3_ids = data_table3_id.map((row) => ({
    ...row,
    date_format: formatDate(row.login_date),
  }));

  const table3_columns = [
    {
      field: "full_name",
      headerName: "User",
      width: 150,
      cellClassName: getCellClassT2,
    },
    {
      field: "date_format",
      headerName: "Last Login",
      width: 120,
      cellClassName: getCellClassT2,
    },
    {
      field: "days_since_login",
      headerName: "Days since last login",
      width: 150,
      cellClassName: getCellClassT2,
    },
  ];
  const handleCellClickTable3 = (params, event) => {
    if (
      setActiveCellTable3 &&
      setActiveCellTable3.id === params.id &&
      setActiveCellTable3.field === params.field
    ) {
      setActiveCellTable3(null);
      handelCrossFilter(null, "table3");
    } else {
      setActiveCellTable3({ id: params.id, field: params.field });
      handelCrossFilter(params, "table3");
    }
  };

  // TABLE 1:3
  // Group the data by customer_name
  const computeAverageDaysByCustomer = (data) => {
    if (!data) {
      return [];
    }
    const groupedData = data.reduce((acc, curr) => {
      if (!acc[curr.customer_name]) {
        acc[curr.customer_name] = [];
      }
      acc[curr.customer_name].push(curr);
      return acc;
    }, {});

    const averages = [];
    for (let customer_name in groupedData) {
      const totalDays = groupedData[customer_name].reduce(
        (acc, curr) => acc + curr.days_since_login,
        0
      );
      const avgDays = totalDays / groupedData[customer_name].length;
      averages.push({ customer_name, avgDays });
    }
    return averages;
  };

  const table4_data = computeAverageDaysByCustomer(userLoginSummary);
  //   const table4_data = averageDaysByCustomer
  //     ? [...averageDaysByCustomer].sort((a, b) => b.full_name - a.full_name)
  //     : [];
  const data_table4_ids = table4_data.map((row) => ({ ...row, id: uuidv4() }));

  const table4_columns = [
    {
      field: "customer_name",
      headerName: "Customer",
      width: 150,
      cellClassName: getCellClassT1,
    },
    {
      field: "avgDays",
      headerName: "Avg Days Since Last Login",
      width: 200,
      cellClassName: getCellClassT1,
    },
  ];
  const handleCellClickTable4 = (params, event) => {
    if (
      setActiveCellTable4 &&
      setActiveCellTable4.id === params.id &&
      setActiveCellTable4.field === params.field
    ) {
      setActiveCellTable4(null);
      handelCrossFilter(null, "table4");
    } else {
      setActiveCellTable4({ id: params.id, field: params.field });
      handelCrossFilter(params, "table4");
    }
  };

  ///

  useEffect(() => {
    const customerIds = filterOptions.customer.map(
      (customer) => customer.value
    );

    const userIds = filterOptions.user.map((user) => user.value);
    const userTypeIds = filterOptions.usertype.map(
      (usertype) => usertype.value
    );

    const filterPayload = {
      customer: customerIds,
      user: userIds,
      usertype: userTypeIds,
    };

    dispatch(fetchUserMonitorReportClickByDates(filterPayload));
    dispatch(fetchUserMonitorReportClickByReport(filterPayload));
    dispatch(fetchUserMonitorSummaryCustomerUsers(filterPayload));
    dispatch(fetchUserMonitorUserLogins(filterPayload));
    dispatch(fetchUserMonitorSummaryUserLoginSummary(filterPayload));
  }, [filterOptions]);
  const formatAndSortClicks = () => {
    if (!clicksByDate || !clicksByDate.length) {
      return [];
    }

    // First, format the date for each item
    const formattedClicks = clicksByDate.map((click) => {
      const dateObj = new Date(click.date);
      const formattedDate = dateObj.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });

      return {
        ...click,
        date: formattedDate,
      };
    });

    // Then, sort the formatted data by date
    formattedClicks.sort((a, b) => new Date(a.date) - new Date(b.date));
    return formattedClicks;
  };

  const customerUsersSorted = customerUsers
    ? [...customerUsers].sort((a, b) => b.user_count - a.user_count)
    : [];

  const userLoginsSorted = userLogins
    ? [...userLogins].sort((a, b) => b.login_count - a.login_count)
    : [];
  const clicksByReportSorted = clicksByReport
    ? [...clicksByReport].sort(
        (a, b) => b.report_click_count - a.report_click_count
      )
    : [];
  const calculatePercentage = (user_logins) => {
    if (user_logins && user_logins.length && userList) {
      const totalUsers = userList.length;

      const usersWithMoreThanOneLogin = user_logins.filter(
        (user) => user.login_count > 1
      ).length;

      const percentage = Math.round(
        (usersWithMoreThanOneLogin / totalUsers) * 100
      );
      return percentage;
    } else {
      return 0;
    }
  };

  const concurrent_percentage = calculatePercentage(userLogins);

  const handleChangeAutoComplete = (event, values, name) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [name]: values,
    }));
  };
  const customerCountCompute = () => {
    if (activeBarIndexCustomer) {
      return "1";
    } else if (customerList) {
      return customerList.length;
    } else {
      return "";
    }
  };

  const inactiveUserCountCompute = () => {
    return userList.filter((user) => user.isActive === false).length;
  };

  const toggles = [
    { value: "graph", label: "Graphs" },
    { value: "table", label: "Tables" },
  ];

  function setBoxHeight(data, density) {
    switch (density) {
      case "compact":
        return data.length * 35 + 200;
      case "standard":
        return data.length * 45 + 250;
      case "comfortable":
        return data.length * 70 + 300;
      default:
        return 1000;
    }
  }

  return (
    <Grid container spacing={2} id="powerbi-export">
      <>
        {!customerListLoading && customerList ? (
          <>
            <AutocompleteComponent
              title="Customer"
              name="customer"
              options={customerList.map(
                ({ customer_field, customer_name }) => ({
                  label: customer_name,
                  value: customer_field,
                })
              )}
              value={filterOptions.customer}
              isMultiple={true}
              onChange={handleChangeAutoComplete}
              xs={12}
              sm={"auto"}
              md={"auto"}
              lg={"auto"}
            />{" "}
          </>
        ) : (
          <></>
        )}
      </>

      <>
        {!userListLoading && userList ? (
          <>
            {" "}
            <AutocompleteComponent
              title="User"
              name="user"
              options={userList.map(({ user_id, full_name }) => ({
                label: full_name,
                value: user_id,
              }))}
              value={filterOptions.user}
              isMultiple={true}
              onChange={handleChangeAutoComplete}
              xs={12}
              sm={"auto"}
              md={"auto"}
              lg={"auto"}
            />
          </>
        ) : (
          <></>
        )}
      </>

      {!userTypeListLoading && userTypeList ? (
        <>
          <AutocompleteComponent
            title="User Type"
            name="usertype"
            options={userTypeList.map(({ user_type_field, user_types }) => ({
              label: user_types,
              value: user_type_field,
            }))}
            value={filterOptions.usertype}
            isMultiple={true}
            onChange={handleChangeAutoComplete}
            xs={12}
            sm={"auto"}
            md={"auto"}
            lg={"auto"}
          />
        </>
      ) : (
        <></>
      )}

      <Grid item xs={12} sm={12}></Grid>
      <BasicKpiCardComponent
        title="Number of Users"
        value={userList ? userList.length : ""}
        xs={12}
        sm={"auto"}
        md={"auto"}
        lg={"auto"}
        isLoading={userListLoading}
      />

      <BasicKpiCardComponent
        title="Inactive Accounts"
        value={inactiveUserCountCompute()}
        xs={12}
        sm={"auto"}
        md={"auto"}
        lg={"auto"}
        isLoading={userListLoading}
      />

      <BasicKpiCardComponent
        title="% Concurrent Users"
        value={`${concurrent_percentage}%`}
        xs={12}
        sm={"auto"}
        md={"auto"}
        lg={"auto"}
        isLoading={userListLoading}
      />

      <BasicKpiCardComponent
        title="Number of Customers"
        value={customerCountCompute()}
        xs={12}
        sm={"auto"}
        md={"auto"}
        lg={"auto"}
        isLoading={customerListLoading}
      />
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SwitchButtonGroup
          toggles={toggles}
          value={visualSwitch}
          onChange={(event) => setVisualSwitch(event.target.value)}
          colors={colors}
        />
      </Grid>

      {visualSwitch === "graph" ? (
        <>
          {/* ROW 2 */}
          <ChartCard
            title="Users by Customer"
            chartContent={
              <>
                {!customerUsersLoading && customerUsers ? (
                  <BarChartComponent
                    data={customerUsersSorted}
                    xaxisDataKey={"customer_name"}
                    xaxisLabel={"Customer Name"}
                    yaxisDataKey={"user_count"}
                    yaxisLabel={"User Count"}
                    fillColors={colors.chartColors}
                    singleColor={false}
                    onBarClick={handleCustomerBarClick}
                    activeBarIndex={activeBarIndexCustomer}
                  />
                ) : (
                  <>
                    <BarGraphLoader />
                  </>
                )}
              </>
            }
            xs={12}
            sm={12}
            md={6}
            lg={6}
            height={"400px"}
          />

          <ChartCard
            title="Number of logins by user for past 30 days"
            chartContent={
              <>
                {!userLoginsLoading && userLogins ? (
                  <BarChartComponent
                    data={userLoginsSorted}
                    xaxisDataKey={"first_name"}
                    xaxisLabel={"First Name"}
                    yaxisDataKey={"login_count"}
                    yaxisLabel={"Login Count"}
                    fillColors={colors.chartColors}
                    singleColor={true}
                    onBarClick={handleUserBarClick}
                    activeBarIndex={activeBarIndexUser}
                  />
                ) : (
                  <>
                    <BarGraphLoader />
                  </>
                )}
              </>
            }
            xs={12}
            sm={12}
            md={6}
            lg={6}
            height={"400px"}
          />

          <ChartCard
            title="Number of clicks by report"
            chartContent={
              <>
                {!clicksByReportLoading && clicksByReport ? (
                  <HorizontalBarChartComponent
                    data={clicksByReportSorted}
                    xaxisDataKey={"report_name"}
                    xaxisLabel={"Report Name"}
                    yaxisDataKey={"report_click_count"}
                    yaxisLabel={"Click Count"}
                    fillColors={colors.chartColors}
                    onBarClick={handleReportBarClick}
                    activeBarIndex={activeBarIndexReport}
                  />
                ) : (
                  <>
                    <BarGraphLoader />
                  </>
                )}
              </>
            }
            xs={12}
            sm={12}
            md={6}
            lg={6}
            height={"400px"}
          />

          <ChartCard
            title="Number of clicks by Date"
            chartContent={
              <>
                {!clicksByDateLoading && clicksByDate ? (
                  <LineChartComponent
                    data={formatAndSortClicks()}
                    xaxisDataKey={"date"}
                    xaxisLabel={"Date"}
                    yaxisDataKey={"report_click_count"}
                    yaxisLabel={"Report Clicks"}
                  />
                ) : (
                  <>
                    <BarGraphLoader />
                  </>
                )}
              </>
            }
            xs={12}
            sm={12}
            md={6}
            lg={6}
            height={"400px"}
          />
        </>
      ) : (
        <>
          {/* TABLE 1:1 */}

          <ChartCard
            title={"Total Users by Customer"}
            chartContent={
              <DisplayTableComponent
                data={data_table1_ids}
                columns={table1_columns}
                height={"1000px"}
                onCellClick={handleCellClickTable1}
                onGridClick={handleOutsideClick}
                isLoading={customerUsersLoading}
              />
            }
            xs={12}
            sm={12}
            md={6}
            lg={3}
            height={setBoxHeight(data_table1_ids, "compact") + "px"}
          />

          {/* TABLE 1:2 */}

          <ChartCard
            title={"Names of users by Customer"}
            chartContent={
              <DisplayTableComponent
                data={data_table2_ids}
                columns={table2_columns}
                onCellClick={handleCellClickTable2}
                isLoading={customerUserNamesLoading}
              />
            }
            xs={12}
            sm={12}
            md={6}
            lg={3}
            height={setBoxHeight(data_table2_ids, "compact") + "px"}
          />

          {/* TABLE 1:3 */}

          <ChartCard
            title={"Login Dates by User"}
            chartContent={
              <DisplayTableComponent
                data={data_table3_ids}
                columns={table3_columns}
                onCellClick={handleCellClickTable3}
                isLoading={userLoginSummaryLoading}
              />
            }
            xs={12}
            sm={12}
            md={6}
            lg={3}
            height={setBoxHeight(data_table3_ids, "compact") + "px"}
          />

          {/* TABLE 1:4 */}
          <ChartCard
            title={"Login Dates by Customer"}
            chartContent={
              <DisplayTableComponent
                data={data_table4_ids}
                columns={table4_columns}
                onCellClick={handleCellClickTable4}
                isLoading={userLoginSummaryLoading}
              />
            }
            xs={12}
            sm={12}
            md={6}
            lg={3}
            height={setBoxHeight(data_table4_ids, "compact") + "px"}
          />
        </>
      )}
    </Grid>
  );
};

export default UserMonitorComponent;
