import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import Business from "assets/images/Business.mp4";
import AtomLogo from "assets/images/AtomLogoOnly.png";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

const Section = () => {
  const [isOpen, setOpen] = useState(false);

  const styleObj = {
    letterSpacing: "3px",
  };

  return (
    <React.Fragment>
      <section className="bg-home3" id="home">
        <video autoPlay muted id="myVideo">
          <source src={Business} type="video/mp4" />
        </video>
        <div className="bg-overlay"></div>
        <Container lg="8" md="12" xs="12">
          <Row className="justify-content-center">
            <Col lg="8" md="12" xs="12">
              <div className="text-center text-white">
                <img src={AtomLogo} height="250" alt="Atom DTS logo" />

                <br className="mb-3"></br>
                <h1 className="mb-4 mt-5" style={styleObj}>
                  <span className="text-primary">D</span>ata.{" "}
                  <span className="text-primary">T</span>echnology.{" "}
                  <span className="text-primary">S</span>ervices.{" "}
                </h1>
                <p className="text-white-50 fs-16">
                  Atom DTS unlocks the true power of your data, allowing you the
                  ability to recognise potential opportunities within a live
                  environment - within the Fresh Produce industry and beyond.
                  The continuous support our team provides, enables you to focus
                  on what really matters - your stakeholders.
                </p>
                <Link to="/solutions" className="btn btn-primary mt-3">
                  DISCOVER OUR SOLUTIONS
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="position-relative">
        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1440"
            height="150"
            preserveAspectRatio="none"
            viewBox="0 0 1440 150"
          >
            <defs>
              <mask id="SvgjsMask1022">
                <rect width="1440" height="250" fill="#ffffff"></rect>
              </mask>
            </defs>
          </svg>
        </div>
      </div>
      <ModalVideo
        channel="vimeo"
        videoId="287684225"
        autoplay
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default Section;
