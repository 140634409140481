import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Grid from "@mui/material/Grid";
import "./select.scss";

export default function DatePickerComponent({value, name, label, onChange, xs, sm, md, lg, onlyWeekNow=false}) {

  const getWeekStartEnd = () => {
    const now = dayjs();
    const monday = now.startOf('week').add(1, 'day'); // Adjust if week start is different
    const friday = monday.add(4, 'days');

    return { start: monday, end: friday };
  };

  // Function to check if a date should be disabled
  const shouldDisableDate = (date) => {
    const { start, end } = getWeekStartEnd();
    return !date.isBetween(start, end, 'day', '[]'); // '[]' includes start and end dates
  };

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="select-label">{label}</div>
        <DatePicker
          hiddenLabel
          value={value}
          onChange={(e) => onChange(e, "", name)}
          format="DD MMM YYYY"
          shouldDisableDate={onlyWeekNow ? shouldDisableDate: undefined}
        />
    </LocalizationProvider>
    </Grid>
  );
}