export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#fff",
          200: "#adadaf",
          300: "#848488",
          400: "#5b5b60",
          500: "#ffffff",
          600: "#28282d",
          700: "#1F1F23",
          800: "#141416",
          900: "#1F1F23",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },

        orangeAccent: {
          100: "#fbdccf",
          200: "#f7ba9f",
          300: "#f49770",
          400: "#f07540",
          500: "#ec5210",
          600: "#bd420d",
          700: "#8e310a",
          800: "#5e2106",
          900: "#2f1003",
        },

        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },

        chartColors: {
          0: "#ec5210",
          1: "#136aa1",
          2: "#3CEC10",
          3: "#C010EC",
          4: "#F2CE3D",
          5: "#EC103C",
          6: "#2f1003",
          7: "#2f030c",
          8: "#26032f",
          9: "#0c2f03",
          10: "#03222f",
          11: "#5e2106",
          12: "#5e0618",
          13: "#4d065e",
          14: "#185e06",
          15: "#06445e",
          16: "#8e310a",
          17: "#8e0a24",
          18: "#730a8e",
          19: "#248e0a",
          20: "#0a668e",
          21: "#bd420d",
          22: "#bd0d30",
          23: "#9a0dbd",
          24: "#30bd0d",
          25: "#0d88bd",
          26: "#f07540",
          27: "#f04063",
          28: "#cd40f0",
          29: "#63f040",
          30: "#40bbf0",
          31: "#f49770",
          32: "#f4708a",
          33: "#d970f4",
          34: "#8af470",
          35: "#70ccf4",
          36: "#f7ba9f",
          37: "#f79fb1",
          38: "#e69ff7",
          39: "#b1f79f",
          40: "#9fddf7",
          41: "#fbdccf",
          42: "#fbcfd8",
          43: "#f2cffb",
          44: "#d8fbcf",
          45: "#cfeefb",
        },
      }
    : {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#3b3b42",
          200: "#141416",
          300: "#1e1e22",
          400: "#FFFFFF",
          500: "#F0F0F0",
          600: "#5b5b60",
          700: "#848488",
          800: "#adadaf",
          900: "#FFFFFF",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },

        orangeAccent: {
          100: "#2f1003",
          200: "#5e2106",
          300: "#8e310a",
          400: "#bd420d",
          500: "#ec5210",
          600: "#f07540",
          700: "#f49770",
          800: "#f7ba9f",
          900: "#fbdccf",
        },

        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
        chartColors: {
          0: "#1BD6DE",
          1: "#FF857B",
          2: "#91E978",
          3: "#FFC847",
          4: "#686F9E",
          5: "#FF82B5",
          6: "#2AAFB6",
          7: "#FFAB42",
          8: "#80C84D",
          9: "#FFCA3F",
          10: "#6875AB",
          11: "#FF8750",
          12: "#39BE8C",
          13: "#FF738F",
          14: "#FF8F42",
          15: "#6DCC98",
          16: "#45A774",
          17: "#FF9E43",
          18: "#FF5431",
          19: "#FF9245",
          20: "#96C2D0",
          21: "#FF4F3A",
          22: "#8FD46F",
          23: "#FFD83B",
          24: "#7073AF",
          25: "#FF8FA8",
          26: "#44B2D0",
          27: "#FF9C42",
          28: "#8A9F61",
          29: "#FFCB3F",
          30: "#4A72B8",
          31: "#D8A142",
          32: "#5CA78E",
          33: "#FF7086",
          34: "#1BC1DA",
          35: "#D3C697",
          36: "#81A978",
          37: "#FF9C43",
          38: "#7276B0",
          39: "#FF8C8F",
          40: "#54A5CD",
          41: "#D4B869",
          42: "#5EA58D",
          43: "#FF8BB0",
          44: "#40A4BC",
          45: "#D6A542",
          46: "#5E9D8B",
          47: "#FF9742",
          48: "#8393B4",
          49: "#FF888F",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[100],
            },
          }),
    },

    components: {

      MuiInputLabel: {
        styleOverrides: {
          root: {
            textAlign: "center",
            fontStyle: "italic",
            fontSize: "12px",
            letterSpacing: "0px",
            color: colors.primary[100],
            border: "1px solid #D6D6D6",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            minWidth: "150px",
            // height: "40px",
            "&::before": {
              display: "none",
            },
            backgroundColor: colors.primary[900],
            border: "0px none #D6D6D6",
            borderRadius: "5px",
            opacity: 1,
            color: colors.primary[100],
            paddingLeft: "15px",
            '&:hover': {
              borderColor: colors.orangeAccent[800], // Your desired color for the outline
            },
          },
          input: {
            padding: '5px 0 5px 0 !important', // Adjust padding as needed, can help with text alignment'
          },

        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            // Add other root styles if needed
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.orangeAccent[800], // Adjust the color as needed
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.orangeAccent[800], // Your desired color for the outline
            },
          },
          notchedOutline: {
            // Default styles for the notched outline
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: `2px solid ${colors.orangeAccent[800]}`, // Adjust this as needed
            },
          },
        },
      },

      MuiSelect: {
        styleOverrides: {
          select: {
            paddingLeft: "0px",
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: colors.primary[900],
            margin: 0,
            padding: 0,
            borderRadius: "8px",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: colors.primary[900],
            margin: 0,
            padding: 0,
            borderRadius: "8px",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: 0,
            borderRadius: "8px",
            '&:hover': {
              backgroundColor: `${colors.orangeAccent[900]} !important` , // Background color when hovered over
            },
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: colors.primary[100], // your color for unchecked state
            "&.Mui-checked": {
              color: colors.orangeAccent[500], // your color for checked state
            },
          },
        },
      },

      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            opacity: 1,
            marginBottom: "10px",
          },
        },
      },

      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? "#3b3b42" : "#FFFFFF",
            margin: 0,
            padding: 0,
            "&::before": {
              display: "none",
            },
            "& > .MuiAccordionSummary-content": {
              borderRadius: "8px",
              margin: 0,
              minHeight: "64px",
              paddingLeft: "14px",
              paddingRight: "14px",
              paddingTop: "12px",
              paddingBottom: "12px",
            },
            "&.Mui-expanded": {
              margin: 0,
              borderRadiusBottom: "8px",
            },
          },
          expandIcon: {
            color: "#EC5210",
            marginRight: "10px",
            transition: "transform 0.3s ease-in-out",
            transform: "rotate(90deg)",
          },
          expandIconExpanded: {
            color: "#EC5210",
            marginLeft: "10px",
            transform: "rotate(180deg)",
          },
        },
      },
   
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? "#1f1f23cc" : "#8888882b",
            margin: 0,
            paddingTop: "23px",
            paddingLeft: "27px",
            paddingRight: "27px",
            paddingBottom: "23px",
            borderRadiusBottom: "8px",
          },
        },
      },
    },

    typography: {
      fontFamily: ["Lato", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontSize: 18,
      },
      h6: {
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};
