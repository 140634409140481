import BlogList from "./BlogList";
import "./blog.scss";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import blog404 from "../../../assets/images/blog/blog404.png";

const BlogHome = () => {
  const { articleLoading, articleList } = useSelector(
    (state) => state.blogInfo
  );
  if (articleList && !articleLoading) {
    const filterdArticles = articleList.filter((a) => a.category == 1);
    return (
      <section className="section bg-light" id="blog">
        <Container fluid className="container-xl">
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center mb-5">
                <h2>Featured Articles</h2>
                <div className="title-border mt-2"></div>
              </div>
            </Col>
          </Row>
          <BlogList blogs={filterdArticles} />
        </Container>
      </section>
    );
  } else {
    return (
      <section className="section bg-light" id="blog">
        <Container fluid className="container-xl">
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center mb-5">
                <h2>Featured Articles</h2>
                <div className="title-border mt-2"></div>
              </div>
              <img width="100%" src={blog404} alt="Blogs not available" />
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
};

export default BlogHome;
