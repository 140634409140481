import { useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import BasicKpiCardComponent from "../../components/cards/BasicKpiCard";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DisplayTableComponent from "../../components/charts/DisplayTable";
import BarChartComponent from "../../components/charts/BarChart";
import { themeSettings } from "../../theme";
import ChartCard from "../../components/cards/ChartCard";
import Grid from "@mui/material/Grid";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns"; // Import the date-fns library for date formatting
import { tokens } from "../../theme";

import {
  TICKET_PRIORITY_SORT,
  CLIENT_STATUSSES,
  ATOM_ACCOUNT_NAMES,
  PROPHET_ACCOUNT_NAMES,
} from "hubl/utils/config";
import { useEffect } from "react";

function countEntriesByXAxis(data, xAxis, colors, type) {
  const countData = data.reduce((acc, entry) => {
    const value = entry[xAxis] ? entry[xAxis].toLowerCase() : "unknown"; // Convert value to lowercase
    if (!acc[value]) {
      acc[value] = 0;
    }
    acc[value]++;
    return acc;
  }, {});

  switch (type) {
    case "bar":
      return Object.keys(countData).map((value, index) => ({
        [xAxis]: value,
        count: countData[value],
        fill: colors[index],
      }));
    case "pie":
      return Object.keys(countData).map((value, index) => ({
        name: value,
        value: countData[value],
        fill: colors[index],
      }));

    default:
      return [];
  }
}

const TicketOverviewDashboard = ({ ticket_data, comparison, toggles }) => {
  const darkMode = useSelector((state) => state.darkMode);
  const theme = createTheme(themeSettings(darkMode ? "dark" : "light"));
  const colors = tokens(theme.palette.mode);
  const [ticketSubset, setTicketSubset] = useState([]);
  const [ticketSubsetIds, setTicketSubsetIds] = useState([]);
  const [table1Tickets, setTable1Tickets] = useState([]);

  const [activeBarIndexOrg, setActiveBarIndexOrg] = useState(null);
  const [activeBarIndexTax, setActiveBarIndexTax] = useState(null);
  const [clickedCellTable1, setClickedCellTable1] = useState(null);
  const [selectedKPI, setSelectedKPI] = useState(null);

  useEffect(() => {
    if (ticket_data) {
      setTicketSubset(ticket_data);
      setTable1Tickets(ticket_data);
    }
  }, []);

  useEffect(() => {
    if (ticket_data) {
      setTicketSubset(ticket_data);
      setTable1Tickets(ticket_data);
    }
  }, [ticket_data]);

  useEffect(() => {
    if (ticketSubset) {
      const ticket_data_ids = ticketSubset.map((row) => ({
        ...row,
        id: uuidv4(),
      }));
      setTicketSubsetIds(ticket_data_ids);
    }
  }, [ticketSubset]);
  // useEffect(() => {
  //   if( ticket_data){
  //     const table1Data = processData(ticket_data, comparison)
  //     const table1Data_ids = table1Data.map((row) => ({ ...row, id: uuidv4() }));
  //     setTable1Tickets(table1Data_ids, comparison)
  //   }
  // }, [comparison]);

  const handelCrossFilter = (data, visual) => {
    let filterList = [];
    if (!data) {
      setTicketSubset(ticket_data);
      setTable1Tickets(ticket_data);
      setActiveBarIndexOrg(null);
      setClickedCellTable1(null);
      return;
    } else {
      switch (visual) {
        // BAR 1:1 IS CLICKED
        case "bar":
          switch (data.activeLabel) {
            case "Prophet":
              const prophetFilter = (ticket) =>
                PROPHET_ACCOUNT_NAMES.includes(ticket.Department_Name);
              filterList.push(prophetFilter);

              setActiveBarIndexOrg(data.activeLabel);
              break;

            case "Atom DTS":
              const atomFilter = (ticket) =>
                ATOM_ACCOUNT_NAMES.includes(ticket.Department_Name);
              filterList.push(atomFilter);
              setActiveBarIndexOrg(data.activeLabel);
              break;

            case "Customer":
              const customerFilter = (ticket) =>
                CLIENT_STATUSSES.includes(ticket.Log_or_Ticket_Status);
              filterList.push(customerFilter);
              setActiveBarIndexOrg(data.activeLabel);
              break;
            default:
              break;
          }

          break;
        case "table":
          const rowFilter = (ticket) =>
            ticket.Log_or_Ticket_Priority === data.row.Priority;
          filterList.push(rowFilter);
          if (data.field !== "Total" && data.field !== "Overdue tickets") {
            const colFilter = (ticket) =>
              ticket.daysOverdueCategory === data.field;
            filterList.push(colFilter);
          }
          break;

        case "kpi":
          const kpiFilter = (ticket) => ticket.Log_or_Ticket_Priority === data;
          filterList.push(kpiFilter);
          break;
      }

      const combinedTickets = ticket_data.filter((ticket) =>
        filterList.every((filter) => filter(ticket))
      );

      if (visual == "bar" || visual == "kpi") {
        setTable1Tickets(combinedTickets);
      }
      setTicketSubset(combinedTickets);
    }
  };
  // HELPER FUNCTIONS
  const generatePieData = (data, colors) => {
    if (data === undefined) return [];
    return Object.keys(data).map((bucket, index) => ({
      name: bucket,
      value: data[bucket],
      fill: colors.chartColors[index % colors.chartColors.length],
    }));
  };

  // KPI CARDS
  const handleKpiClick = (data, index) => {
    if (selectedKPI === data) {
      handelCrossFilter(null, "kpi");
      setSelectedKPI(null);
    } else {
      setSelectedKPI(data);
      handelCrossFilter(data, "kpi");
    }
  };

  // BAR GRAPH 1:1 SETUP
  const ticketsByOrganization = (all_tickets) => {
    const customer_tickets = all_tickets.filter((ticket) =>
      CLIENT_STATUSSES.includes(ticket.Log_or_Ticket_Status)
    );

    // const tickets_test = [...new Set(all_tickets.map((ticket) => ticket.Log_or_Ticket_Priority))]
    // console.log(tickets_test)
    const non_customer_tickets = all_tickets.filter(
      (ticket) => !CLIENT_STATUSSES.includes(ticket.Log_or_Ticket_Status)
    );

    const atom_tickets = non_customer_tickets.filter((ticket) =>
      ATOM_ACCOUNT_NAMES.includes(ticket.Department_Name)
    );

    const prophet_tickets = non_customer_tickets.filter((ticket) =>
      PROPHET_ACCOUNT_NAMES.includes(ticket.Department_Name)
    );

    return {
      "Atom DTS": atom_tickets.length,
      Prophet: prophet_tickets.length,
      Customer: customer_tickets.length,
    };
  };
  const bar1Data = generatePieData(ticketsByOrganization(ticket_data), colors);
  const handleOrgBarClick = (data, index) => {
    handelCrossFilter(data, "bar");
  };

  // TABLE 1:2 SETUP
  const getCellClass = (params) => {
    return clickedCellTable1 &&
      clickedCellTable1.id === params.id &&
      clickedCellTable1.field === params.field
      ? "clicked"
      : "";
  };
  const createTable = (processedData) => {
    let aggregated = {};
    if (!processedData) return [];
    processedData.forEach((ticket) => {
      const priority = ticket.Log_or_Ticket_Priority;
      if (!aggregated[priority]) {
        aggregated[priority] = {
          Priority: priority,
          "1-7 days": 0,
          "8-14 days": 0,
          "15-21 days": 0,
          "22-30 days": 0,
          "30+ days": 0,
          "Not Overdue": 0,
          Total: 0,
        };
      }
      aggregated[priority][ticket.daysOverdueCategory]++;
      aggregated[priority]["Total"]++;
    });

    return Object.keys(aggregated)
      .sort((a, b) => TICKET_PRIORITY_SORT[a] - TICKET_PRIORITY_SORT[b])
      .map((key, index) => ({
        id: index, // <-- This line assigns a unique id to each row
        Priority: key,
        ...aggregated[key],
      }));
  };

  const table1_data = createTable(table1Tickets);
  const table1_columns = [
    { field: "Priority", headerName: "Overdue tickets", width: 180 },
    {
      field: "Not Overdue",
      headerName: "Not Overdue",
      width: 100,
      cellClassName: getCellClass,
    },
    {
      field: "1-7 days",
      headerName: "1-7 days",
      width: 80,
      cellClassName: getCellClass,
    },
    {
      field: "8-14 days",
      headerName: "8-14 days",
      width: 80,
      cellClassName: getCellClass,
    },
    {
      field: "15-21 days",
      headerName: "15-21 days",
      width: 80,
      cellClassName: getCellClass,
    },
    {
      field: "22-30 days",
      headerName: "22-30 days",
      width: 80,
      cellClassName: getCellClass,
    },
    {
      field: "30+ days",
      headerName: "30+ days",
      width: 80,
      cellClassName: getCellClass,
    },
    {
      field: "Total",
      headerName: "Total",
      width: 60,
      cellClassName: getCellClass,
    },
  ];

  const handleCellClickTable1 = (params, event) => {
    if (
      clickedCellTable1 &&
      clickedCellTable1.id === params.id &&
      clickedCellTable1.field === params.field
    ) {
      setClickedCellTable1(null);
      handelCrossFilter(null, "table");
    } else {
      setClickedCellTable1({ id: params.id, field: params.field });
      handelCrossFilter(params, "table");
    }
  };

  // TABLE 2:1 SETUP
  const summary_table_columns = [
    { field: "Log_or_Ticket_Number", headerName: "#" },
    {
      field: "Department_Name",
      headerName: "Department",
      width: 200,
    },

    {
      field: "Subject",
      headerName: "Subject",
      width: 400,
    },
    {
      field: "Taxonomy",
      headerName: "Taxonomy",
      width: 250,
    },
    {
      field: "Log_or_Ticket_Status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "Link",
      headerName: "Link",
      width: 200,

      renderCell: (params) => {
        const link = params.value;
        let backgroundColor = "";
        let textColor = "";

        if (link.includes("zoho")) {
          backgroundColor = colors.greenAccent[600];
          textColor = colors.primary[100];
        } else if (link.includes("supportweb")) {
          backgroundColor = colors.greenAccent[700];
          textColor = colors.primary[100];
        } else {
          backgroundColor = colors.greenAccent[800];
          textColor = colors.primary[100];
        }

        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={backgroundColor}
            borderRadius="4px"
          >
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: textColor }}
            >
              {link.includes("zoho")
                ? "Zoho"
                : link.includes("supportweb")
                ? "SupportWeb"
                : ""}
            </a>
          </Box>
        );
      },
    },
    {
      field: "Is_Overdue",
      headerName: "Is Overdue",
    },
    {
      field: "Date_Created",
      headerName: "Date Created",
      width: 150,
      valueGetter: (params) => {
        if (!params.row.Date_Created) return null;
        const dateCreated = params.row.Date_Created;
        const formattedDate = format(new Date(dateCreated), "dd MMMM yyyy");
        return formattedDate;
      },
    },
    {
      field: "Due_Date",
      headerName: "Due Date",
      width: 150,
      valueGetter: (params) => {
        if (!params.row.Due_Date) return null;
        const dateCreated = params.row.Due_Date;
        const formattedDate = format(new Date(dateCreated), "dd MMMM yyyy");
        return formattedDate;
      },
    },
  ];

  function setBoxHeight(data, density) {
    switch (density) {
      case "compact":
        return data.length * 36 + 167;
      case "standard":
        return data.length * 52 + 200;
      case "comfortable":
        return data.length * 67 + 232;
      default:
        return data.length * 67 + 232;
    }
  }

  // RETURN STATMENT
  return (
    <Grid container spacing={2}>
      <BasicKpiCardComponent
        title="Total Number of Tickets"
        value={ticketSubset.length}
        xs={12}
        sm={"auto"}
        md={"auto"}
        lg={"auto"}
      />
      <BasicKpiCardComponent
        title="Mission Critical Tickets"
        value={
          ticketSubset.filter(
            (ticket) =>
              ticket.Log_or_Ticket_Priority === "Mission Critical (max 6 hrs)"
          ).length
        }
        onKpiClick={() => handleKpiClick("Mission Critical (max 6 hrs)")}
        xs={12}
        sm={"auto"}
        md={"auto"}
        lg={"auto"}
        isClicked={selectedKPI === "Mission Critical (max 6 hrs)"}
      />
      <BasicKpiCardComponent
        title="High Priotity Tickets"
        value={
          ticketSubset.filter(
            (ticket) => ticket.Log_or_Ticket_Priority === "High (max 2 days)"
          ).length
        }
        onKpiClick={() => handleKpiClick("High (max 2 days)")}
        xs={12}
        sm={"auto"}
        md={"auto"}
        lg={"auto"}
        isClicked={selectedKPI === "High (max 2 days)"}
      />
      <BasicKpiCardComponent
        title="Medium Priority Tickets"
        value={
          ticketSubset.filter(
            (ticket) =>
              ticket.Log_or_Ticket_Priority &&
              ticket.Log_or_Ticket_Priority.includes("Medium")
          ).length
        }
        onKpiClick={() => handleKpiClick("Medium (max 5 days)")}
        xs={12}
        sm={"auto"}
        md={"auto"}
        lg={"auto"}
        isClicked={selectedKPI === "Medium (max 5 days)"}
      />{" "}
      <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
      <ChartCard
        title="Number of Tickets by Organisation"
        chartContent={
          <BarChartComponent
            data={bar1Data}
            xaxisDataKey={"name"}
            xaxisLabel={"Category"}
            yaxisDataKey={"value"}
            yaxisLabel={"Number of Tickets"}
            fillColors={colors.chartColors}
            singleColor={false}
            onBarClick={handleOrgBarClick}
            activeBarIndex={activeBarIndexOrg}
          />

          // <PieChartComponent data={pieData} theme={theme} />
        }
        xs={12}
        sm={12}
        md={12}
        lg={4}
        height={"400px"}
      />{" "}
      <ChartCard
        title={`Number of tickets by ${
          toggles.find((t) => t.value === comparison).label
        }`}
        chartContent={
          <DisplayTableComponent
            data={table1_data}
            columns={table1_columns}
            height={"400px"}
            onCellClick={handleCellClickTable1}
          />
        }
        xs={12}
        sm={12}
        md={12}
        lg={8}
        height={"400px"}
      />
      <ChartCard
        title="Ticket Overview"
        chartContent={
          <DisplayTableComponent
            data={ticketSubsetIds}
            columns={summary_table_columns}
          />
        }
        xs={12}
        sm={12}
        md={12}
        lg={12}
        height={setBoxHeight(ticketSubsetIds, "standard") + "px"}
      />
      {/* <ChartCard
          title="Number of tickets by Taxonomy"
          chartContent={
            <BarChartComponent
              data={taxonomyData}
              xaxisDataKey={"Taxonomy"}
              xaxisLabel={"Taxonomy"}
              yaxisDataKey={"count"}
              yaxisLabel={"Count"}
              fillColors={colors.chartColors}
              singleColor={false}
              onBarClick={handleTaxBarClick}
              activeBarIndex={activeBarIndexTax}
            />
          }
          xs={12}
          sm={12}
          height={"400px"}
        /> */}
    </Grid>
  );
};

export default TicketOverviewDashboard;
