import React from "react";
import { Col, Container, Row } from "reactstrap";

import JD from "../../../assets/images/users/img-1.jpg";
import Charl from "../../../assets/images/users/img-2.jpg";
import ChristovWyk from "../../../assets/images/users/ChristovWyk.png";
import Yolande from "../../../assets/images/users/Yolande.png";

const TeamCard = (props) => {
  return (
    <React.Fragment>
      <Col lg={3} md={6} sm={6} xs={6}>
        <div className="team-box text-center">
          <div className="team-img position-relative">
            <div
              style={{
                backgroundColor: "#f8f8f8",
              }}
            >
              {/* <LazyLoadImage
                src={props.imgsrc}
                className="img-fluid rounded"
                alt="Atom DTS staff"
              /> */}
              <img
                src={props.imgsrc}
                className="img-fluid rounded"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="team-content">
              <ul className="list-inline mb-0 p-0">
                {props.isIcon.map((item, key) => (
                  <li key={key} className="list-inline-item">
                    <a
                      href="https://www.linkedin.com/company/prophet-plc/people/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className={item.icon}></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="p-3">
            <a
              href="https://www.linkedin.com/company/prophet-plc/people/"
              target="_blank"
              className="text-primary"
              rel="noopener noreferrer"
            >
              <h5 className="fs-18 mb-1">{props.name}</h5>
            </a>
            <p className="mb-0">{props.designation}</p>
          </div>
        </div>
        {/* <!--end team-box--> */}
      </Col>
    </React.Fragment>
  );
};

const TeamDataGeneral = [
  {
    imgsrc: JD,
    name: "JD van Wyk",
    designation: "Director",
    isIcon: [{ icon: "mdi mdi-linkedin" }],
    linkSocial: "https://www.linkedin.com/company/prophet-plc/people/",
  },
  {
    imgsrc: Charl,
    name: "Charl van Loggerenberg",
    designation: "Director",
    isIcon: [{ icon: "mdi mdi-linkedin" }],
    linkSocial: "",
  },
];

const TeamData = () => {
  return (
    <React.Fragment>
      <section className="mt-5 mb-5" id="general">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center">
                <p className="text-uppercase text-muted mb-2 mt-3">
                  Meet The Team
                </p>
                <h3 className="mb-2">Amazing and talented people work here</h3>
                {/* <div className="title-border mt-3"></div> */}
                {/* <p className="title-desc text-muted mt-3">
                  Porttitor dolor donec pulvinar tortor nisi quis dapibus tortor
                  commodo sed Pellentesque hendrerit pellentesque libero nec
                  sollicitudin.
                </p> */}
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg={12}>
              <div className="header-title text-start">
                <h3 className="mb-5 mt-2 text-primary text-center">
                  Senior management
                </h3>
                {/* <div className="title-border mt-3"></div> */}
                {/* <p className="title-desc text-muted mt-3">
                  Porttitor dolor donec pulvinar tortor nisi quis dapibus tortor
                  commodo sed Pellentesque hendrerit pellentesque libero nec
                  sollicitudin.
                </p> */}
              </div>
            </Col>
            {/* Team Detail Component Start */}

            {TeamDataGeneral.map((value, idx) => (
              <TeamCard
                key={idx}
                imgsrc={value.imgsrc}
                name={value.name}
                designation={value.designation}
                isIcon={value.isIcon}
                linkSocial={value.linkSocial}
              />
            ))}

            {/* Team Detail Component End */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default TeamData;
