import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { List } from "react-content-loader";
import { CustomToolbar } from "./CustomGridToolbar";
import { setTableDensity } from "slice/recharts";

function DisplayTableComponent({
  data,
  columns,
  showCheckboxes = false,
  onCellClick,
  isLoading = false,
  onGridClick,
  handleCellEditCommit,
  handleSelectionChange,
  rowsPerPage,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const keys = data.length > 0 ? Object.keys(data[0]) : null; // Get the keys from the first object

  const { tableDensity } = useSelector((state) => state.rechartDashboards);
  const handleCellClick = (params, event) => {
    event.stopPropagation();
    if (onCellClick) {
      onCellClick(params, event);
    }
  };
  const handleDensityChange = (newDensity) => {
    if (newDensity !== tableDensity) {
      console.log("Handling density change");
      console.log(newDensity);
    }
  };

  function setBoxHeight(pageSize) {
    switch (tableDensity) {
      case "compact":
        return pageSize * 36 + 107;
      case "standard":
        return pageSize * 52 + 140;
      case "comfortable":
        return pageSize * 67 + 172;
      default:
        return pageSize * 67 + 172;
    }
  }
  // Define default rowsPerPageOptions
  const defaultRowsPerPageOptions = [10, 20, 50, 100, 200];

  // Ensure rowsPerPage is included in rowsPerPageOptions
  const rowsPerPageOptions = rowsPerPage
    ? Array.from(new Set([...defaultRowsPerPageOptions, rowsPerPage])).sort(
        (a, b) => a - b
      )
    : defaultRowsPerPageOptions;

  // Default value for rowsPerPage if not provided
  const pageSize = rowsPerPage || 100; // Default page size if rowsPerPage is not provided

  return keys ? (
    <Box
      height={`${setBoxHeight(data.length)}px`}
      onClick={() => {
        if (onGridClick) {
          onGridClick();
        }
      }}
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.orangeAccent[500],
          color: colors.primary[500],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary,
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.primary[900],
          color: colors.primary[100],
        },
        "& .MuiDataGrid-cell:hover": {
          backgroundColor: colors.primary[600],
          cursor: "pointer",
        },
        "& .MuiCheckbox-root": {
          color: `${colors.primary[300]} !important`,
        },
        "& .MuiBox-root": {
          color: `${colors.orangeAccent[500]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[400]} !important`,
        },
        "& .clicked": {
          backgroundColor: colors.orangeAccent[600],
        },
      }}
    >
      {!isLoading ? (
        <>
          <DataGrid
            checkboxSelection={showCheckboxes}
            rows={data}
            columns={columns}
            // pageSize={pageSize}
            // rowsPerPageOptions={rowsPerPageOptions}
            onCellClick={handleCellClick}
            onCellEditCommit={handleCellEditCommit}
            onSelectionModelChange={handleSelectionChange}
            components={{
              Toolbar: () => (
                <CustomToolbar onDensityChange={handleDensityChange} />
              ),
            }}
            sx={{
              ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                {
                  marginTop: "1em",
                  marginBottom: "1em",
                },
              "& .MuiDataGrid-footerContainer": {
                display: "none",
              },
            }}
          />
        </>
      ) : (
        <List />
      )}
    </Box>
  ) : (
    <></>
  );
}

export default DisplayTableComponent;
