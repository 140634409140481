import React from "react";
import { useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { tokens } from "../../theme";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "../../theme";
import { CustomTooltip } from "./CustomTooltip";
// const data = [
//   { name: "Jan", uv: 4000, pv: 2400, amt: 2400 },
//   { name: "Feb", uv: 3000, pv: 1398, amt: 2210 },
//   { name: "Mar", uv: 2000, pv: 9800, amt: 2290 },
//   { name: "Apr", uv: 2780, pv: 3908, amt: 2000 },
//   { name: "May", uv: 1890, pv: 4800, amt: 2181 },
//   { name: "Jun", uv: 2390, pv: 3800, amt: 2500 },
//   { name: "Jul", uv: 3490, pv: 4300, amt: 2100 },
// ];

// Customized X-axis tick component
const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-30)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedLegend = ({ yaxisLabel, color }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <svg width="30" height="10">
      <line x1="0" y1="5" x2="30" y2="5" stroke={color} strokeWidth="2" />
    </svg>
    <div style={{ color: color, marginLeft: "5px" }}>{yaxisLabel}</div>
  </div>
);

const LineChartComponent = ({
  data,
  xaxisDataKey,
  xaxisLabel,
  yaxisDataKey,
  yaxisLabel,
}) => {
  const darkMode = useSelector((state) => state.darkMode);
  const theme = createTheme(themeSettings(darkMode ? "dark" : "light"));
  const colors = tokens(theme.palette.mode);

  return (
    <ResponsiveContainer width="98%" height="100%">
      <LineChart
        data={data}
        margin={{ top: 10, right: 50, left: 10, bottom: 50 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xaxisDataKey} tick={<CustomizedAxisTick />}>
          {/* <Label value={xaxisLabel} position="insideBottom" />  */}
        </XAxis>
        <YAxis>
          <Label
            value={yaxisLabel}
            angle={-90}
            position="insideLeft"
            style={{ textAnchor: "middle" }}
          />
        </YAxis>

        <Tooltip
          content={
            <CustomTooltip
              xaxisDataKey={xaxisDataKey}
              xaxisLabel={xaxisLabel}
              yaxisDataKey={yaxisDataKey}
              yaxisLabel={yaxisLabel}
            />
          }
        />
        <Legend
          layout="vertical"
          verticalAlign="top"
          align="center"
          content={
            <CustomizedLegend
              yaxisLabel={yaxisLabel}
              color={colors.orangeAccent[500]}
            />
          }
        />
        <Line
          type="monotone"
          dataKey={yaxisDataKey}
          stroke={colors.orangeAccent[500]}
          activeDot={{ r: 8 }}
          label={yaxisLabel} // Added custom label to Line
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
