// import React from "react";
// import logolight from "../../assets/images/logo-light.png";
// import { Col, Container, Row } from "reactstrap";
// import { Link } from "react-router-dom";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";

// const Footer = () => {
//   return (
//     <React.Fragment>
//       <footer className="bg-footer">
//         <Container>
//           {/* <Row className="bg-light">
//             <Col>
//               <p>Get connected with us on social networks:</p>
//             </Col>
//             <Col>
//               <a href="" className="me-4 text-reset">
//                 <LinkedInIcon />
//               </a>
//             </Col>
//           </Row> */}
//           <Row className="justify-content-center">
//             <Col lg={6} sm={12}>
//               <div className="mt-3 text-center">
//                 <Link to="/">
//                   <img src={logolight} alt="" height="30" />
//                 </Link>
//                 <p className="text-white-50 text-center mt-3 pt-2 mb-0 ">
//                   Atom DTS delivers world-class application support, modern
//                   Business Intelligence (BI) solutions and wide-ranging
//                   Information Technology (IT) services through a one-stop
//                   delivery channel to our clients in the fresh produce industry
//                   and beyond.
//                 </p>
//               </div>
//             </Col>
//             <Col className="align-items-center d-none d-lg-block" lg="6">
//               <Row className="justify-content-center mb-3 mt-3">
//                 <Col lg="auto">
//                   <h4 className="text-white text-center mt-1 fs-24">
//                     Get in Touch
//                   </h4>
//                 </Col>
//               </Row>
//               <Row className="justify-content-center">
//                 <Col lg="auto">
//                   <i className="mdi mdi-phone text-primary h3 text-center"></i>
//                 </Col>
//                 <Col lg="auto" className="mt-1">
//                   <a
//                     className="fs-18 text-white-50 text-center"
//                     href="tel:+27218529521"
//                   >
//                     +27 (21) 852 9521
//                   </a>
//                 </Col>
//                 <Col lg="auto">
//                   <i className="mdi mdi-email text-primary h3 text-center"></i>
//                 </Col>
//                 <Col lg="auto" className="mt-1">
//                   <a
//                     className="fs-18 text-white-50 text-center"
//                     href="mailto:services@atomdts.co.za"
//                   >
//                     services@atomdts.co.za
//                   </a>
//                 </Col>
//                 {/* <Col lg="auto">
//                   <i className="mdi mdi-map-marker text-primary h3 text-center"></i>
//                 </Col> */}
//                 {/* <Col lg="auto" className="mt-1">
//                   <a
//                     className="fs-18 text-white-50 text-center"
//                     href="https://goo.gl/maps/Zp2B1ByKCs9wskJM9"
//                     target="_blank"
//                   >
//                     Visit Us
//                   </a>
//                 </Col> */}

//                 {/* <Col lg="4">
//                   <a
//                     className="text-muted mb-0"
//                     href="https://goo.gl/maps/Zp2B1ByKCs9wskJM9"
//                     target="_blank"
//                   ></a>
//                 </Col> */}
//               </Row>
//               <Row className="justify-content-center">
//                 <Col lg="auto">
//                   <a
//                     className="fs-18 text-white-50 text-center"
//                     href="https://goo.gl/maps/Zp2B1ByKCs9wskJM9"
//                     target="_blank"
//                   >
//                     <p className="text-white-50 text-center fs-12 mt-3">
//                       Oakmont Building, Somerset Links Office Park, De Beers
//                       Ave, Somerset West, 7130
//                     </p>
//                   </a>
//                 </Col>
//               </Row>
//             </Col>
//           </Row>
//         </Container>
//       </footer>
//     </React.Fragment>
//   );
// };

// export default Footer;

import React from "react";
import logolight from "assets/images/AtomLogo.png";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { FaPhone, FaEnvelope } from "react-icons/fa";

// const COMPANY_SLOGAN = "Your company slogan here";
// const COMPANY_LOGO_URL = "https://your.logo.url.here";
// const PRODUCT_LINKS = [
//   { name: "Product 1", url: "http://example.com" },
//   { name: "Product 2", url: "http://example.com" },
//   { name: "Product 3", url: "http://example.com" },
// ];
// const GENERAL_LINKS = [
//   { name: "Link 1", url: "http://example.com" },
//   { name: "Link 2", url: "http://example.com" },
//   { name: "Link 3", url: "http://example.com" },
// ];
// const PHONE_NUMBER = "+1 (123) 456-7890";
// const EMAIL_ADDRESS = "info@example.com";
// const PHYSICAL_ADDRESS = "123 Fake St, Faketown, FK 12345";

const Footer = () => (
  <footer className="bg-footer">
    {/* <div
      style={{
        width: "100%",
        backgroundColor: "#ec5210",
        color: "white",
        padding: "20px",
        textAlign: "center",
      }}
    >
      {COMPANY_SLOGAN}
    </div> */}

    <Container>
      <Row className="mt-3">
        <Col md="3" sm="12" className="mb-3">
          <NavLink to="/">
            {" "}
            <img
              src={logolight}
              alt="Company Logo"
              style={{ maxWidth: "30%", height: "auto" }}
            />
          </NavLink>

          {/* <p className="text-white-50 fs-12 mt-2 pt-2 mb-0">
            Atom DTS delivers world-class application support, modern Business
            Intelligence (BI) solutions and wide-ranging Information Technology
            (IT) services through a one-stop delivery channel to our clients in
            the fresh produce industry and beyond.
          </p> */}
        </Col>
        <Col md="3" sm="6" className="mb-3">
          <p className="text-white fs-18">Our Solutions</p>
          <Row>
            {" "}
            <NavLink to="/data">
              <p className="fs-17 text-white-50 ">Data Solutions</p>
            </NavLink>
          </Row>
          <Row>
            {" "}
            <NavLink to="/technology">
              <p className="fs-17 text-white-50 ">Technology Solutions</p>
            </NavLink>
          </Row>
          <Row>
            {" "}
            <NavLink to="/services">
              <p className="fs-17 text-white-50 ">Service Solutions</p>
            </NavLink>
          </Row>
        </Col>
        <Col md="3" sm="6" className="mb-3">
          <p className="text-white fs-18">General</p>
          <Row>
            {" "}
            <NavLink to="/about">
              <p className="fs-17 text-white-50 "> About Us</p>
            </NavLink>
          </Row>
          <Row>
            {" "}
            <NavLink to="/vacancies">
              <p className="fs-17 text-white-50 "> Career Opportunities</p>
            </NavLink>
          </Row>
        </Col>
        <Col md="3" sm="12">
          <p className="text-white fs-18">Contact Us</p>
          <p className="text-white-50 fs-16">
            <a
              className="fs-16 text-white-50 text-center"
              href="tel:+27218529521"
            >
              <FaPhone
                className="text-primary"
                style={{ marginRight: "8px" }}
              />{" "}
              +27 (21) 852 9521
            </a>
          </p>

          <a
            className="fs-16 text-white-50 text-center"
            href="mailto:services@atomdts.co.za"
          >
            <FaEnvelope
              className="text-primary"
              style={{ marginRight: "10px" }}
            />
            services@atomdts.co.za
          </a>
          <a
            className="fs-18 text-white-50"
            href="https://goo.gl/maps/Zp2B1ByKCs9wskJM9"
            target="_blank"
          >
            <p className="text-white-50 fs-14 mt-3">
              Oakmont Building, Somerset Links Office Park, De Beers Ave,
              Somerset West, 7130
            </p>
          </a>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
