import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Form } from "reactstrap";
import {
  fetchUserGroupAll,
  postReportGroup,
  getReportGroupList,
  getCustomerList,
} from "slice/admin";
import { useSelector, useDispatch } from "react-redux";
import { BulletList } from "react-content-loader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";

import AutocompleteComponent from "../../components/autocomplete/Autocomplete";
import { updateUserGroup } from "../../../slice/admin";
function UpdateReportGroupComponent() {
  const [selectedCustomer, setCustomer] = useState(null);
  const [selectedGroup, setGroup] = useState(null);

  const {
    reportGroupList,
    reportGroupListLoading,
    customerList,
    userGroupReport,
    userGroupReportLoading,
  } = useSelector((state) => state.adminInfo);
  const [formData, setFormData] = useState({
    customer: "",
    id: "",
    name: "",
    report: [],
  });

  const customerListMapped = customerList
    ? customerList.map(({ id, name }) => ({
        label: name,
        value: id.toString(),
      }))
    : null;

  const reportGroupListMapped = reportGroupList
    ? reportGroupList.map(({ id, name }) => ({
        label: name,
        value: id.toString(),
      }))
    : null;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCustomerList());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCustomer) {
      setGroup(null);
      dispatch(getReportGroupList(selectedCustomer.value));
    }
  }, [dispatch, selectedCustomer]);

  useEffect(() => {
    if (selectedGroup) {
      dispatch(
        fetchUserGroupAll({
          report_group_id: selectedGroup.value,
          customer_id: selectedCustomer.value,
        })
      );
    }
  }, [dispatch, selectedGroup]);

  const handleToggle = (value) => {
    dispatch(updateUserGroup({ report_id: value }));
  };

  const handleInputChange = (event, value, name) => {
    setFormData((prevOptions) => ({
      ...prevOptions,
      [name]: value.value,
    }));
    switch (name) {
      case "customer":
        setCustomer(value);
        break;
      case "id":
        setGroup(value);
        setFormData((prevOptions) => ({
          ...prevOptions,
          name: value.label,
        }));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (userGroupReport) {
      const selectedIds = userGroupReport
        .filter((report) => report.is_selected === 1)
        .map((report) => report.report_id);
      setFormData((prevOptions) => ({
        ...prevOptions,
        report: selectedIds,
      }));
    }
  }, [userGroupReport]);

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(postReportGroup(formData)).then((e) => {
      if (!e.error) {
        setFormData({
          customer: "",
          id: "",
          report: [],
        });

        setGroup(null);
        setCustomer(null);

        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    });
  };

  const colArray = [];
  for (let i = 0; i < 2; i++) {
    colArray.push(<BulletList />);
  }

  return (
    <Form>
      <Grid container spacing={2}>
        <AutocompleteComponent
          title="Customer"
          name="customer"
          options={customerListMapped}
          value={selectedCustomer}
          isMultiple={false}
          onChange={handleInputChange}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        />

        {selectedCustomer && reportGroupList && !reportGroupListLoading ? (
          <>
            <AutocompleteComponent
              title="User Group"
              name="id"
              options={reportGroupListMapped}
              value={selectedGroup}
              isMultiple={false}
              onChange={handleInputChange}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            />
          </>
        ) : (
          <Grid item xs={12} sm={12}></Grid>
        )}

        {/* <TextField
            label="Report Group Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          <SelectComponent
            title="Customer"
            name="customer"
            options={customerListMapped}
            value={selectedCustomer}
            isMultiple={false}
            onChange={handleInputChange}
          /> */}

        {selectedGroup && userGroupReport && !userGroupReportLoading ? (
          <>
            <Grid item xs={12} sm={4}>
              <List>
                {userGroupReport.map(({ report_id, name, is_selected }) => (
                  <ListItem key={report_id}>
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText
                      id={`switch-list-label-${report_id}`}
                      primary={name}
                    />
                    <Switch
                      edge="end"
                      onChange={() => handleToggle(report_id)}
                      checked={is_selected}
                      inputProps={{
                        "aria-labelledby": `switch-list-label-${report_id}`,
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </Button>{" "}
            </Grid>
          </>
        ) : (
          <></>
        )}

        {userGroupReportLoading ? (
          <Grid xs={12} sm={4}>
            {colArray}
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Form>
  );
}

export default UpdateReportGroupComponent;
