import React from "react";
import { Col, Container, Row, Card } from "reactstrap";
import Vimeo from "@u-wave/react-vimeo";
import CyclingLogos from "../../hubl/components/logoSwitcher/CyclingLogos";
import { Link } from "react-router-dom";

const VideoSec = () => {
  return (
    <>
      <section className="section bg-home9">
        <div className="bg-overlay"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg="auto" md="auto" sm="auto" xs="auto">
              <h2 className="text-white">Product Spotlight</h2>
              <div className="title-border"></div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col lg="8" xs="auto">
              <Row className="justify-content-center ">
                <Col
                  lg="6"
                  md="6"
                  sm="6"
                  xs="9"
                  className="col align-self-center"
                >
                  <Card
                    style={{
                      backgroundColor: "transparent",
                      border: 0,
                    }}
                    className="card-transparen mb-3"
                  >
                    {/* <Image
                      src={FreshHUBL}
                      fluid
                      className="image mt-2 mb-2 text-center"
                    /> */}
                    <CyclingLogos />
                  </Card>
                </Col>
              </Row>

              <p className="text-white fs-16 text-center">
                Powered by Atom's analytics architecture, this web-based
                analytics portal allows you to access your business data from
                anywhere and at any time.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3" id="product-spotlight">
            <Row className="mt-0  justify-content-center">
              <Col lg="8" xs="12">
                <Vimeo
                  classname="vimeo"
                  video="824709068"
                  autoplay={false}
                  responsive={true}
                />
              </Col>
            </Row>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col lg="auto" md="auto" sm="auto" xs="auto">
              <Link to="/atomabout" className="btn btn-primary mt-3">
                Learn More
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default VideoSec;
