import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getClientList,
  getReportStatusList,
  putReportStatusUpdate,
} from "slice/pbi";
import { updateReportStatus } from "slice/pbi";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AutocompleteComponent from "../../components/autocomplete/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import "./admin.scss";
import { BulletList } from "react-content-loader";

// const buttonObj = {
//   height: "30px",
//   width: "100px",
//   paddingLeft: "15px",
//   paddingRight: "15px",
//   marginRight: "16px",
//   margin: "15px",
//   backgroundColor: "#FFFFFF",
//   borderColor: "#ec5210",
//   borderRadius: "20px",
// };

/* UI Properties */

export default function ReportListStatusComponent() {
  const { pbiClients, pbiReportStatusList, pbiReportStatusListLoading } =
    useSelector((state) => state.pbiReducer);
  const dispatch = useDispatch();
  const handleToggle = (value) => {
    dispatch(updateReportStatus({ report_id: value,  }));
  };

  const [customer, setCustomer] = useState(null);

  const handleChangeAutoComplete = (event, values) => {
    setCustomer(values);
  };

  useEffect(() => {
    dispatch(getClientList());
  }, [dispatch]);

  const pbiClientsMapped = pbiClients.map(({ name, client_id }) => ({
    label: name,
    value: client_id ? client_id.toString() : null,
  }));

  const onFetch = (e) => {
    dispatch(getReportStatusList(customer.value));
  };

  const onSubmit = (e) => {
    dispatch(putReportStatusUpdate(pbiReportStatusList));

    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const colArray = [];
  for (let i = 0; i < 2; i++) {
    colArray.push(
      <Grid item xs={12} sm={12} key={i}>
        <BulletList />
      </Grid>
    );
  }
  return (
    <Grid container spacing={2}>
        <AutocompleteComponent
          title="Customer"
          name="customer"
          options={pbiClientsMapped}
          value={customer}
          isMultiple={false}
          onChange={handleChangeAutoComplete}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        />
      <Grid item xs={12} sm={"auto"}>
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => onFetch(e)}
          // style={buttonObj}
        >
          Fetch
        </Button>
      </Grid>

      <Grid item xs={12} sm={12}></Grid>
      {!pbiReportStatusListLoading && pbiReportStatusList ? (
        <>
          <Grid item xs={12} sm={4}>
            <List>
              {pbiReportStatusList.map(({ report_id, name, is_active }) => (
                <ListItem key={report_id}>
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText
                    id={`switch-list-label-${report_id}`}
                    primary={name}
                  />
                  <Switch
                    edge="end"
                    onChange={() => handleToggle(report_id)}
                    checked={is_active}
                    inputProps={{
                      "aria-labelledby": `switch-list-label-${report_id}`,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>{" "}
          <Grid item xs={12} sm={8}></Grid>
          <Grid item xs={12} sm={"auto"}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={(e) => onSubmit(e)}
              // style={buttonObj}
            >
              Submit
            </Button>
          </Grid>
        </>
      ) : (
        <>
          {pbiReportStatusListLoading ? (
            <Grid item xs={12} sm={12}>
              {colArray}
            </Grid>
          ) : (
            <></>
          )}
        </>
      )}
    </Grid>
  );
}
