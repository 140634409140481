import React, { useState, useEffect } from "react";
import logo1 from "../../../assets/images/logo/FreightHUBLdark.png";
import logo2 from "../../../assets/images/logo/FreshHUBLdark.png";
import logo3 from "../../../assets/images/logo/WineHUBLdark.png";
import styles from "./CyclingLogosLogin.module.css";
import { Col, Row } from "reactstrap";

const CyclingLogosLogin = () => {
  const logos = [logo1, logo2, logo3];
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentLogoIndex((prevIndex) => (prevIndex + 1) % logos.length);
    }, 3000); // Change the logo every 3 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, []); // The empty array ensures this effect only runs on mount and unmount

  return (
    <div className={styles.logoContainer}>
      {logos.map((logo, index) => (
        <img
          key={index}
          src={logo}
          alt={`Logo ${index + 1}`}
          className={`${styles.logo} ${
            currentLogoIndex === index ? styles.visible : ""
          }`}
        />
      ))}
    </div>
  );
};

export default CyclingLogosLogin;
