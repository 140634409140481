import VacancyList from "../components/blog/VacanciesList";
import "../components/blog/blog.scss";
import blog404 from "../../assets/images/blog/404CareerOpp.png";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

const VacanciesCont = () => {
  const { vacancyLoading, vacancyList } = useSelector(
    (state) => state.vacancyInfo
  );
  if (vacancyList && !vacancyLoading) {
    return (
      <section className="section bg-light" id="vacancy">
        <Container>
          <VacancyList vacancies={vacancyList} />
        </Container>
      </section>
    );
  } else {
    return (
      <Container>
        <Row className="justify-content-center">
          <Col lg={7}>
            <div className="header-title text-center mb-5"></div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={10}>
            <img width="100%" src={blog404} alt="blog404" />
          </Col>
        </Row>
      </Container>
    );
  }
};

export default VacanciesCont;
