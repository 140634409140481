import React from "react";
import { Col, Container, Row } from "reactstrap";

import Christo from "../../../assets/images/users/Christo.png";
import Christovw from "../../../assets/images/users/ChristovWyk.png";

const TeamCard = (props) => {
  return (
    <React.Fragment>
      <Col lg={3} md={6} sm={6} xs={6}>
        <div className="team-box text-center">
          <div className="team-img position-relative">
            <div
              style={{
                backgroundColor: "#f8f8f8",
              }}
            >
              <img
                src={props.imgsrc}
                className="img-fluid rounded"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="team-content">
              <ul className="list-inline mb-0 p-0">
                {props.isIcon.map((item, key) => (
                  <li key={key} className="list-inline-item">
                    <a
                      href="https://www.linkedin.com/company/prophet-plc/people/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className={item.icon}></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="p-3">
            <a
              href="https://www.linkedin.com/company/prophet-plc/people/"
              target="_blank"
              className="text-primary"
              rel="noopener noreferrer"
            >
              <h5 className="fs-18 mb-1">{props.name}</h5>
            </a>
            <p className="mb-0">{props.designation}</p>
          </div>
        </div>
        {/* <!--end team-box--> */}
      </Col>
    </React.Fragment>
  );
};

const TeamDataTech = [
  {
    imgsrc: Christo,
    name: "Christo Griessel",
    designation: "Team Lead – Technology Department",
    isIcon: [{ icon: "mdi mdi-linkedin" }],
  },
  {
    imgsrc: Christovw,
    name: "Christo van Wyk",
    designation: "IT Technician",
    isIcon: [{ icon: "mdi mdi-linkedin" }],
  },
];

const TeamData = () => {
  return (
    <React.Fragment>
      <section className="mt-5 mb-5" id="technology">
        <Container>
          <Row className="mt-5">
            <Col lg={12}>
              <div className="header-title text-start">
                <h4 className="mb-5 mt-2 text-primary text-center">
                  Technology
                </h4>
                {/* <div className="title-border mt-3"></div> */}
                {/* <p className="title-desc text-muted mt-3">
                  Porttitor dolor donec pulvinar tortor nisi quis dapibus tortor
                  commodo sed Pellentesque hendrerit pellentesque libero nec
                  sollicitudin.
                </p> */}
              </div>
            </Col>
            {/* Team Detail Component Start */}

            {TeamDataTech.map((value, idx) => (
              <TeamCard
                key={idx}
                imgsrc={value.imgsrc}
                name={value.name}
                designation={value.designation}
                isIcon={value.isIcon}
                linkSocial={value.linkSocial}
              />
            ))}

            {/* Team Detail Component End */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default TeamData;
