import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import KPILoader from "../loaders/KpiLoader";
const BasicKpiCardComponent = ({
  title,
  value,
  xs,
  sm,
  md,
  lg,
  onKpiClick,
  isClicked,
  isLoading = false,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleKpiClick = (data) => {
    if (onKpiClick) {
      onKpiClick(data);
    }
  };
  
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} >
      <Card
        className="cardKpi"
        sx={{
          boxShadow: 0,
          backgroundColor: isClicked ? colors.orangeAccent[700] : "",
          "&:hover": {
            boxShadow: `0 4px 20px ${colors.orangeAccent[700]}`, // Orange shadow on hover
          },
          height:"120px",
          width:"250px",
        }}
        
      >
        <CardHeader
          title={
            <Typography variant="h5" fontWeight="bold" textAlign="center">
              {title}
            </Typography>
          }
          sx={{ paddingBottom: "10px" }}
        />

        <CardContent sx={{ cursor: "pointer" , padding: 0}} onClick={handleKpiClick}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            {!isLoading ? (
              <Typography variant="h2" component="h2" textAlign="center">
                {value}
              </Typography>
            ) : (
              <KPILoader />
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default BasicKpiCardComponent;
