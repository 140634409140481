import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
const ChartCard = ({
  title,
  chartContent,
  height,
  xs,
  sm,
  md,
  lg,
  onCardClick,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const cardStyleContent = {
    height: height,
  };

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      <Card
        className="cardKpi"
        sx={{
          boxShadow: 0,
          "&:hover": {
            boxShadow: `0 4px 20px ${colors.orangeAccent[700]}`, // Orange shadow on hover
          },
        }}
      >
        <CardHeader
          title={
            <Typography variant="h5" fontWeight="bold" textAlign="center">
              {title}
            </Typography>
          }
        />
        <CardContent style={cardStyleContent} sx={{ pt: 0 }}>
          {chartContent}
        </CardContent>
      </Card>
    </Grid>
  );
};
export default ChartCard;
