import React from "react";
import { Col, Container, Row } from "reactstrap";

import Zonnika from "../../../assets/images/users/img-3.jpg";
import Andiswa from "../../../assets/images/users/Andiswa.png";
import Antoinette from "../../../assets/images/users/Antoinette.png";
import Fralise from "../../../assets/images/users/Fralise.png";
import IleneD from "../../../assets/images/users/IlenneD.png";
import Nadine from "../../../assets/images/users/Nadine.png";
import Lesedi from "../../../assets/images/users/Lesedi.png";
import Migael from "../../../assets/images/users/Migael.png";
import Quintin from "../../../assets/images/users/Quintin.png";
import RebekahA from "../../../assets/images/users/RebekahA.png";

const TeamCard = (props) => {
  return (
    <React.Fragment>
      <Col lg={3} md={6} sm={6} xs={6}>
        <div className="team-box text-center" id="services">
          <div className="team-img position-relative">
            <div
              style={{
                backgroundColor: "#f8f8f8",
              }}
            >
              <img
                src={props.imgsrc}
                className="img-fluid rounded"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="team-content">
              <ul className="list-inline mb-0 p-0">
                {props.isIcon.map((item, key) => (
                  <li key={key} className="list-inline-item">
                    <a
                      href="https://www.linkedin.com/company/prophet-plc/people/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className={item.icon}></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="p-3">
            <a
              href="https://www.linkedin.com/company/prophet-plc/people/"
              target="_blank"
              className="text-primary"
              rel="noopener noreferrer"
            >
              <h5 className="fs-18 mb-1">{props.name}</h5>
            </a>
            <p className="mb-0">{props.designation}</p>
          </div>
        </div>
        {/* <!--end team-box--> */}
      </Col>
    </React.Fragment>
  );
};

const TeamDataServices = [
  {
    imgsrc: Zonnika,
    name: "Zonnika Augustyn",
    designation: "Head of Services QX",
    isIcon: [{ icon: "mdi mdi-linkedin" }],
  },
  {
    imgsrc: Fralise,
    name: "Fralise Venter",
    designation: "Team Lead – Services",
    isIcon: [{ icon: "mdi mdi-linkedin" }],
  },
  {
    imgsrc: Andiswa,
    name: "Andiswa Litye",
    designation: "Services Consultant",
    isIcon: [{ icon: "mdi mdi-linkedin" }],
  },
  {
    imgsrc: IleneD,
    name: "Ilene de Villiers",
    designation: "Senior Services Consultant",
    isIcon: [{ icon: "mdi mdi-linkedin" }],
  },
  {
    imgsrc: Lesedi,
    name: "Lesedi Monama",
    designation: "Services Consultant",
    isIcon: [{ icon: "mdi mdi-linkedin" }],
  },
  {
    imgsrc: Migael,
    name: "Migael Slabber",
    designation: "Services Consultant",
    isIcon: [{ icon: "mdi mdi-linkedin" }],
  },
  {
    imgsrc: Nadine,
    name: "Nadine van Aswegen",
    designation: "Senior Services Consultant",
    isIcon: [{ icon: "mdi mdi-linkedin" }],
  },
  {
    imgsrc: Quintin,
    name: "Quintin de Klerk",
    designation: "Senior Services Consultant",
    isIcon: [{ icon: "mdi mdi-linkedin" }],
  },
  {
    imgsrc: RebekahA,
    name: "Rebekah Acutt",
    designation: "Services Consultant",
    isIcon: [{ icon: "mdi mdi-linkedin" }],
  },
];

const TeamServices = () => {
  return (
    <React.Fragment>
      <section className="mt-5 mb-5" id="team">
        <Container>
          <Row className="mt-5">
            <Col lg={12}>
              <div className="header-title text-start">
                <h3 className="mb-5 mt-2 text-primary text-center">Services</h3>
                {/* <div className="title-border mt-3"></div> */}
                {/* <p className="title-desc text-muted mt-3">
                  Porttitor dolor donec pulvinar tortor nisi quis dapibus tortor
                  commodo sed Pellentesque hendrerit pellentesque libero nec
                  sollicitudin.
                </p> */}
              </div>
            </Col>
            {/* Team Detail Component Start */}

            {TeamDataServices.map((value, idx) => (
              <TeamCard
                key={idx}
                imgsrc={value.imgsrc}
                name={value.name}
                designation={value.designation}
                isIcon={value.isIcon}
                linkSocial={value.linkSocial}
              />
            ))}

            {/* Team Detail Component End */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default TeamServices;
