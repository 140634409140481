import { combineReducers } from 'redux'
import userReducer from '../slice/user'
import pbiSlice from '../slice/pbi'
import blogReducer from '../slice/blog'
import vacancyReducer from '../slice/vacancy'
import setReportConfigSlice from '../slice/reportConfig'
import sessionTimeoutSlice from '../slice/session'
import selectDarkModeSlice from '../slice/theme'
import toggleSidebarSlice from '../slice/toggleSidebar'
import selectDashboardSection from '../slice/dashboardSection'
import selectMenuSecionSlice from '../slice/menuSection'
import adminReducer from '../slice/admin'
import userMonitorReducer from '../slice/userMonitorSlice'
import notificationSlice from '../slice/notificationSlice'
import commentsSlice from '../slice/commentSlice'
import ticketSlice from 'slice/ticketSlice'
import rechartsSlice from 'slice/recharts'
import planningSlice from 'slice/planning'

export default combineReducers({
    darkMode: selectDarkModeSlice,
    pbiReducer: pbiSlice,
    rechartDashboards: rechartsSlice,
    userInfo: userReducer,
    adminInfo: adminReducer,
    blogInfo: blogReducer,
    vacancyInfo: vacancyReducer,
    reportConfig: setReportConfigSlice,
    sessionTimeout: sessionTimeoutSlice,
    selectedMenuSection: selectMenuSecionSlice,
    isSidebarToggled: toggleSidebarSlice,
    dashboardSection: selectDashboardSection,
    userMonitor: userMonitorReducer,
    notifications: notificationSlice,
    comments: commentsSlice,
    tickets: ticketSlice,
    planning: planningSlice

})