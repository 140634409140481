import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "./textfield.scss";

export default function TextFieldsComponent({
  title,
  name,
  value,
  placeholder,
  onChange,
  InputProps,
  xs,
  sm,
  md,
  lg,
  maxChars,
  multiline,
}) {
  const limitExceeded = value ? value.length >= maxChars : false;
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      <div className="text-label">{title}</div>{" "}
      {limitExceeded && (
        <div
          style={{
            color: "red",
            fontSize: 10,
            paddingLeft: "5px",
            paddingBottom: "10px",
          }}
        >
          Character limit exceeded. (Max: {maxChars})
        </div>
      )}
      <TextField
        hiddenLabel
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        margin="normal"
        InputProps={InputProps}
        multiline={multiline} // Set multiline prop to true
        fullWidth // Ensure the text field takes the full width of the container
        sx={
          limitExceeded
            ? {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "red",
                  },
                  "&:hover fieldset": {
                    borderColor: "red",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "red",
                  },
                },
                "& .MuiInputBase-root": {
                  paddingTop: "0px", // Adjust padding for top
                  paddingBottom: "0px", // Adjust padding for top
                },
              }
            : {
                "& .MuiInputBase-root": {
                  paddingTop: "0px", // Adjust padding for top
                  paddingBottom: "0px", // Adjust padding for top
                },
              }
        }
      />
    </Grid>
  );
}
