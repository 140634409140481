import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Container } from "react-bootstrap";

export default function CircularIndeterminate() {
  return (
    <Container className="vh-100">
    <div className="d-flex align-items-center justify-content-center custom-height">
        <Box sx={{ display: 'flex' }}>
        <CircularProgress />
        </Box>
    </div>
    </Container>

  );
}
