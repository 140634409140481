import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer,
  Rectangle,
} from "recharts";
import { CustomTooltip } from "./CustomTooltip";
import "./bar.scss";

export const generateFillColors = (data, colorList, singleColor) => {
  if (colorList && data.length) {
    return data.map((item, index) => {
      const colorIndex = index % Object.keys(colorList).length;
      if (singleColor) {
        return { ...item, fill: colorList[0] };
      } else {
        return { ...item, fill: colorList[colorIndex.toString()] };
      }
    });
  } else {
    return {};
  }
};

const BarChartComponent = ({
  data,
  xaxisDataKey,
  xaxisLabel,
  yaxisDataKey,
  yaxisLabel,
  borderBottom = 60,
  fillColors,
  singleColor = false,
  onBarClick,
  activeBarIndex

}) => {


  const containerStyle = { height: "100%", width: "100%" };
  const dataWithFillColors = generateFillColors(data, fillColors, singleColor);

  const CustomBar = (props) => {
    const { fill, x, y, width, height, index } = props;
    let isActive = true
    if (activeBarIndex) {
      isActive =  index === activeBarIndex;
    }
    
    return (
      <g>
        {isActive &&  activeBarIndex &&
         <Rectangle   radius={[3, 3, 0, 0]}
         x={x-5} y={y-10} width={width + 10} height={height+10} fill={fill} />}
  
        <Rectangle
          {...props}
          radius={[3, 3, 0, 0]} // adjust radius to your liking
          fill={isActive ? fill : "grey"}
          x={x}
          y={y}
          width={width}
          height={height}
        />
      </g>
    );
  };
  const handleBarClick = (data) => {
    if (onBarClick) {
      onBarClick(data);
    }
  };
  
  return (
    <ResponsiveContainer width="98%" height="100%" style={containerStyle}      >
      <BarChart
        data={dataWithFillColors}
        margin={{ top: 10, right: 50, left: 10, bottom: borderBottom }}
        onClick={handleBarClick}
        cursor="pointer"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={xaxisDataKey}
          angle={-30}
          textAnchor="end"
          height={60}
          dy={10}
          interval={data.length <= 20 ? 0: Math.ceil(data.length / 50)}
        />

        <YAxis>
          <Label
            value={yaxisLabel}
            angle={-90}
            position="insideLeft"
            style={{ textAnchor: "middle" }}
          />
        </YAxis>
        <Tooltip
          content={
            <CustomTooltip
              xaxisDataKey={xaxisDataKey}
              xaxisLabel={xaxisLabel}
              yaxisDataKey={yaxisDataKey}
              yaxisLabel={yaxisLabel}
            />
          }
        />
        {/* <Legend /> */}
        <Bar
          dataKey={yaxisDataKey}
          shape={(props) => <CustomBar {...props} index={props[xaxisDataKey]} />}
          label={yaxisLabel}
         

        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
