import React, { useEffect } from "react";
import NavBar from "../../Layout/Navbar";
import SectionAbout from "./Section";
import FooterAlt from "../../Layout/FooterAlt";
import BackToTop from "../../components/BackToTop";
import Footer from "../../Layout/Footer";
import VacanciesCont from "../VacanciesCont";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const Vacancies = () => {
  const darkMode = useSelector((state) => state.darkMode);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Career Opportunities</title>
      </Helmet>
      <NavBar isDark={darkMode} />
      <SectionAbout />
      <VacanciesCont />
      <Footer />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};

export default Vacancies;
