import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Collapse,
  Container,
  NavbarToggler,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import Scrollspy from "react-scrollspy";
import logoLight from "assets/images/logo-light.png";
import logoDark from "assets/images/logo-dark.png";
import { NavLink } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.userInfo);
  const [isOpenMenu, setisOpenMenu] = useState(false);
  const [navClass, setnavClass] = useState("");

  const toggle = () => setisOpenMenu(!isOpenMenu);

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavClass("nav-sticky");
    } else {
      setnavClass("");
    }
  }

  const isDarkClass = ""

  const styleObj = {
    paddingTop: "0px",
    paddingLeft: "25px",
    paddingRight: "25px",
  };

  const buttonObj = {
    weight: "400",
    marginTop: "2px",
    marginLeft: "3px",
    paddingLeft: "15px",
    paddingRight: "15px",
    color: "#ffffff",
    backgroundColor: "#ec5210",
    borderColor: "#ec5210",
    borderRadius: "15px",
  };

  const buttonDropD = {
    weight: "400",
    marginTop: "0px",
    marginLeft: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
    height: "35px",
    backgroundColor: "transparent",
    borderColor: "transparent",
    color: "#ffffff",

    borderRadius: "15px",
  };

  // const buttonToggle = {
  //   marginTop: "3px",
  //   marginLeft: "-3px",
  //   marginBottom: "10px",
  //   paddingBottom: "10px",
  //   paddingLeft: "0px",
  //   paddingRight: "0px",
  //   height: "10px",
  //   backgroundColor: "transparent",
  //   borderColor: "transparent",
  //   color: "#ffffff",
  //   borderRadius: "15px",
  // };

  const authLinks = (
    // <li>
    //   <Button
    //     size="sm"
    //     variant="light"
    //     style={buttonObj}
    //     onClick={() => dispatch(logout()).then(() => navigate("/"))}
    //   >
    //     LOGOUT
    //   </Button>
    // </li>
    <>
    <li>
      <Button
        variant="dark"
        size="sm"
        onClick={() => navigate("/hubl")}
        style={buttonObj}
      >
        HUBL
      </Button>
    </li>
  </>
    

  );

  const guestLinks = (
    <>
      <li>
        <Button
          variant="dark"
          size="sm"
          onClick={() => navigate("/login")}
          style={buttonObj}
        >
          HUBL
        </Button>
      </li>
    </>
  );
  return (
    <span>
      <nav
        className={
          "ml-auto navbar navbar-expand-lg fixed-top" +
          isDarkClass +
          " sticky " +
          navClass
        }
        id="navbar"
      >
        <Container fluid style={styleObj}>
          <Link className="navbar-brand logo text-uppercase" to="/">
              <>
                <img src={logoDark} className="logo-dark" alt="" height="30" />
                <img
                  src={logoLight}
                  className="logo-light"
                  alt=""
                  height="30"
                />
              </>
          </Link>
          <NavbarToggler
            className="navbar-toggler"
            type="button"
            onClick={toggle}
          >
            <i className="mdi mdi-menu"></i>
          </NavbarToggler>
          <Collapse
            isOpen={isOpenMenu}
            className="navbar-collapse"
            id="navbarNav"
          >
            <Scrollspy
              offset={-18}
              items={["home", "about", "process", "contact", "dataSection"]}
              className="navbar-nav ms-auto navbar-center"
              id="mySidenav"
            >
              <ul className="navbar-nav">
                <UncontrolledDropdown group>
                  <li className="nav-item">
                    <NavLink to="/solutions" className="nav-link">
                      SOLUTIONS
                    </NavLink>
                  </li>
                  <DropdownToggle caret size="sm" className="nav-toggle" />
                  <DropdownMenu>
                    <DropdownItem header>
                      <NavLink to="/data" className="nav-link">
                        DATA SOLUTIONS
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem header>
                      <NavLink to="/technology" className="nav-link">
                        TECHNOLOGY SOLUTIONS
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem header>
                      {" "}
                      <NavLink to="/services" className="nav-link">
                        SERVICES SOLUTIONS
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/* <li className="nav-item">
                  <NavLink to="/solutions" className="nav-link">
                    SOLUTIONS
                  </NavLink>
                </li> */}

                <li className="nav-item">
                  <NavLink to="/about" className="nav-link">
                    ABOUT
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink to="/hubl" className="nav-link">
                    HUBL
                  </NavLink>
                </li> */}

                <li className="nav-item">
                  <NavLink to="/articles" className="nav-link">
                    ARTICLES
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contact" className="nav-link">
                    CONTACT
                  </NavLink>
                </li>
                {isAuthenticated ? authLinks : guestLinks}
              </ul>
              <br></br>
            </Scrollspy>
          </Collapse>
        </Container>
      </nav>
    </span>
  );
};

export default NavBar;
