import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { putRegisterContact } from "../../../slice/contact";
import Grid from "@mui/material/Grid";
import TextFieldsComponent from "../../components/textfield/TextField";

function RegisterRequestComponent() {
  const [inputs, setInputs] = useState([{ email: "", firstName: "" }]);
  const dispatch = useDispatch();

  function handleInputChange(index, event) {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  }

  function handleAddInput() {
    setInputs([...inputs, { email: "", firstName: "" }]);
  }

  function handleRemoveInput(index) {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = inputs.map(({ email, firstName }) => ({
      email,
      first_name: firstName,
    }));
    dispatch(putRegisterContact(data));
  }


  return (
    <form onSubmit={handleSubmit}>
      {inputs.map((input, index) => (
        <Grid container spacing={2} key={index}>

            <TextFieldsComponent
              name="firstName"
              placeholder="first name"
              value={input.firstName}
              onChange={(e) => handleInputChange(index, e)}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            />

 
            <TextFieldsComponent
              name="email"
              placeholder="email"
              value={input.email}
              onChange={(e) => handleInputChange(index, e)}
              InputProps={{
                endAdornment: input.email && (
                  <Chip
                    label={input.email}
                    onDelete={() =>
                      handleInputChange(index, {
                        target: { name: "email", value: "" },
                      })
                    }
                    style={{ marginLeft: "5px" }}
                  />
                ),
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            />
       
          <Grid item xs={12} sm={8}></Grid>

          {index > 0 && (
            <Grid item xs={"auto"} sm={"auto"}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleRemoveInput(index)}
              >
                Remove
              </Button>{" "}
            </Grid>
          )}

          {index === inputs.length - 1 && (
            <Grid item xs={"auto"} sm={"auto"}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddInput}
              >
                Add
              </Button>{" "}
            </Grid>
          )}
        </Grid>
      ))}
      <Grid container spacing={2} key={"button-container"}>
        <Grid item xs={12} sm={12}>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default RegisterRequestComponent;
