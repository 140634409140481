import { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Logo from "../../../assets/images/logo/HUBL_Default_Logo.png";
import NavbarMain from "../../../website/Layout/Navbar.js";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { reset_password } from "slice/user";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/popup/Popup";
import { Navigate } from "react-router-dom";
import "./password.scss";

const EMAIL_REGEX = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

const formInput = {
  borderRadius: "10px",
  borderColor: "#e3e3e3",
};

const buttonObj = {
  height: "50px",
  width: "250px",
  marginTop: "30px",
  marginLeft: "3px",
  paddingLeft: "15px",
  paddingRight: "15px",
  color: "#ffffff",
  backgroundColor: "#ec5210",
  borderColor: "#ec5210",
  borderRadius: "20px",
};

const ResetPassword = () => {
  const [open, setOpen] = useState(false);
  const [popHeader, setPopHeader] = useState("");
  const [popContent, setPopContent] = useState("");
  const [requestSent, setRequestSent] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
  });
  const { email } = formData;
  let navigate = useNavigate();
  const [validEmail, setValidEmail] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(reset_password(formData)).then((value) => {
      if (!value.payload.success) {
        switch (value.error.message) {
          case "Rejected":
            setPopHeader("Password Reset Failed");
            if (value.payload.detail) {
              setPopContent(value.payload.detail);
            } else {
              setPopContent(value.payload.error);
            }

          default:
        }
      }
    });
    setRequestSent(true);
  };
  if (requestSent) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>Analytics | Reset Password</title>
        <meta name="description" content={"Analytics Portal"} />
      </Helmet>
      <Popup
        open={open}
        header={popHeader}
        content={popContent}
        onClose={handleClose}
      />
      <div className="password">
        <NavbarMain />

        <Container
          className="registerContainer"
          style={{
            marginTop: "auto",
            width: "500px",
            padding: "50px",
          }}
        >
          <Row className="rowWidth">
            <Col>
              <Form>
                <div className="heading-register">
                  <img
                    className="logo-register"
                    src={Logo}
                    alt="register logo"
                  />
                </div>
                <h3 className="heading-title">Request Password Reset</h3>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email*"
                    style={formInput}
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                    required
                    aria-invalid={validEmail ? "false" : "true"}
                    aria-describedby="emailnote"
                  />
                </Form.Group>
                <p
                  id="uidnote"
                  className={!validEmail ? "instructions" : "offscreen"}
                >
                  Must be valid email
                </p>

                <div className="d-grid gap-2 button-container">
                  <Button
                    // variant="dark"
                    type="submit"
                    size="lg"
                    style={buttonObj}
                    onClick={(e) => onSubmit(e)}
                    href={"/"}
                  >
                    Reset Password
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ResetPassword;
