import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../assets/scss/theme.scss";
import { Helmet } from "react-helmet";
import BackToTop from "../components/BackToTop";
import Contact from "../pages/Contact";
import Footer from "./Footer";
import FooterAlt from "./FooterAlt";
import Process from "../pages/Process";
import Services from "../pages/Services";
import NavBar from "./Navbar";
import Section from "./Section";
import BlogHome from "../components/blog/blog";
import VideoSec from "../pages/VideoSection";


export default function Layout() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      // This will push the page to the specific element
      let element = document.getElementById(hash.replace("#", ""));
      if (element) element.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);
  return (
    <React.Fragment>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <NavBar />
      <Section />
      <Services />
      <Process />
      <VideoSec id="product-spotlight" />
      <BlogHome />
      <Contact />
      <Footer />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
}
