import React from "react";
import GoogleMapReact from "google-map-react";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const AnyReactComponent = ({ text }) => (
  <div>
    <a
      className="text-muted mb-0"
      href="https://goo.gl/maps/Zp2B1ByKCs9wskJM9"
      target="_blank"
    >
      <i className="mdi mdi-map-marker text-primary h1"></i>
    </a>
  </div>
);

export default function GoogleMaps() {
  const defaultProps = {
    center: {
      lat: -34.08890833371521,
      lng: 18.823655929755777,
    },
    zoom: 15,
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "400px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDvOz-fOIbvkw1gr-ZqtVnWWUffTmHvyLQ" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
      >
        <AnyReactComponent
          lat={-34.08890833371521}
          lng={18.823655929755777}
          text
        />
      </GoogleMapReact>
    </div>
  );
}
