import BlogList from "../components/blog/BlogList"; 
import "../components/blog/blog.scss";
import blog404 from "../../assets/images/blog/blog404.png";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

const ArticlesCont = () => {
  const { articleLoading, articleList } = useSelector(
    (state) => state.blogInfo
  );
  if (articleList && !articleLoading) {
    return (
      <section className="section bg-light" id="blog">
        <Container>
          <BlogList blogs={articleList} />
        </Container>
      </section>
    );
  } else {
    return (
      <Container>
        <Row className="justify-content-center">
          <Col lg={7}>
            <div className="header-title text-center mb-5"></div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={10}>
            <img width="100%" src={blog404} alt="blog404" />
          </Col>
        </Row>
      </Container>
    );
  }
};

export default ArticlesCont;
