import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Modal from "@mui/material/Modal";
import { Col, Row } from "reactstrap";
import Vimeo from "@u-wave/react-vimeo";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  border: "0px",
  boxShadow: 24,
  borderRadius: "8px",
  p: 1,
};

export default function VideoModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Row className=" justify-content-center">
        <Col xs="auto" lg="auto">
          <Button
            sx={{
              color: "#3b3b42",
              borderRadius: "100px",

              width: "120px",
              height: "45px",
              backgroundColor: "#fff",
              borderColor: "#fff",
              ":hover": {
                bgcolor: "#ec5210",
                color: "#fff",
              },
            }}
            className="m-2"
            size="large"
            variant="contained"
            startIcon={<PlayArrowIcon />}
            onClick={handleOpen}
          >
            Video
          </Button>
        </Col>
      </Row>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Row className="mt-0  justify-content-center">
            <Vimeo
              classname="vimeo"
              video="824709068"
              autoplay={true}
              responsive={true}
            />
          </Row>
        </Box>
      </Modal>
    </div>
  );
}
