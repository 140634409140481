import * as React from 'react';
import Switch from '@mui/material/Switch';
import "./switch.scss";
import Grid from "@mui/material/Grid";
export default function SwitchComponent({checked, onChange, label, xs, sm, md, lg}) {


  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
    <div className="switch-label">{label}</div>
    <Switch
      checked={checked}
      onChange={(e) => onChange(e)}
      inputProps={{ 'aria-label': 'controlled' }}
    /></Grid>
  );
}