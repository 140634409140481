import React from "react";
import { Link } from "react-router-dom";
import "./blog.scss";
import { Col, Row } from "reactstrap";

const VacancyList = ({ vacancies }) => {
  return (
    <Row>
      {vacancies.map((vacancy) => (
        <Col lg="4" md="4" sm="auto" xs="12" key={vacancy.id}>
          <div className="blog-box box-shadow rounded mt-4 p-3">
            <div className="mt-4">
              <Link to={`/vacancy/${vacancy.slug}`} className="primary-link">
                <h5 className="fs-19 mb-1">{vacancy.title}</h5>
              </Link>
              <p className="text-muted mt-2">{vacancy.subtitle}</p>

              <div className="d-flex align-items-center justify-content-between"></div>
              <Row className="justify-content-center m-4">
                <Col xs="auto" md="auto">
                  <Link
                    to={`/vacancy/${vacancy.slug}`}
                    className="btn btn-primary"
                  >
                    Read More
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
          {/* <!--end blog--> */}
        </Col>
      ))}
    </Row>
  );
};

export default VacancyList;
