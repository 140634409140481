import React, { useEffect } from "react";
import { useGridApiContext, GridToolbar } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { setTableDensity } from "slice/recharts";

export function CustomToolbar({ onDensityChange, onToolbarClick }) {
  const apiRef = useGridApiContext();
  const dispatch = useDispatch();

  useEffect(() => {
    // Function to handle density change
    const handleDensityChange = () => {
      const densityValue = apiRef.current.state.density.value;
      onDensityChange(densityValue);
      console.log("Density changed to: ", densityValue);
      dispatch(setTableDensity(densityValue));
    };

    // Subscribe to density change events
    const unsubscribe = apiRef.current.subscribeEvent(
      "densityChange",
      handleDensityChange
    );

    // Unsubscribe on cleanup
    return () => {
      unsubscribe();
    };
  }, [apiRef, onDensityChange]);
  const handleClick = (event) => {
    // Stop event from bubbling up to parent elements
    event.stopPropagation();

    if (onToolbarClick) {
      onToolbarClick(event);
    }
  };
  // Render the default GridToolbar
  return <GridToolbar onClick={handleClick} />;
}
