import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import "./autocomplete.scss";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Grid from "@mui/material/Grid";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function AutocompleteComponent({
  title,
  options,
  value,
  name,
  onChange,
  isMultiple,
  xs,
  sm,
  md,
  lg,
}) {
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      <FormControl fullWidth={false}>
        <div className="autocomplete-label">{title}</div>

        <Autocomplete
          className="autocomplete-object"
          id={`autocomplete-${name}`}
          multiple={isMultiple}
          options={options ? options : []}
          isOptionEqualToValue={(option, value) =>
            value && option ? option.label === value.label : false
          }
          value={value}
          disableCloseOnSelect={isMultiple}
          onChange={(event, newValue) => {
            onChange(event, newValue, name);
          }}
          getOptionLabel={(option) => {
            if (option && option.label && option.label !== null) {
              return option.label.toString();
            } else {
              return "";
            }
          }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" placeholder={title} />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
        />
      </FormControl>{" "}
    </Grid>
  );
}

export default AutocompleteComponent;
