import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Header from "../../components/charts/Header";
import "./planning.scss";
import { useEffect } from "react";
import {
  fetchWineCellarList,
  fetchWineProducerList,
  fetchWineFarmList,

  selectProducer
} from "slice/planning";

function PlanningMainComponent() {
  const dispatch = useDispatch();
  const [ComponentToRender, setComponentToRender] = useState(null);
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const { userPlanningPagesLoading, userPlanningPages, producer, producerList } = useSelector(
    (state) => state.planning
  );
  const { user } = useSelector((state) => state.userInfo);
  const { section } = useSelector((state) => state.selectedMenuSection);

  useEffect(() => {
    dispatch(fetchWineCellarList());
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(fetchWineProducerList({producer: user.planning_producer}))
    }
  }, [user])

  useEffect(() => {
    if (user && producerList) {
      dispatch(selectProducer(producerList[0]))
      dispatch(fetchWineFarmList({producer: user.planning_producer}))
    }
  }, [producerList])

  useEffect(() => {
    if (section && !userPlanningPagesLoading && userPlanningPages) {
      const pages = userPlanningPages.find((d) => d.id === section);

      if (pages) {
      setSelectedDashboard(pages);
      const LazyComponent = React.lazy(() =>
        import(`./${pages.file_name}`)
      );
      setComponentToRender(<LazyComponent />);
    }
  }
  }, [section, userPlanningPagesLoading, userPlanningPages]);

  return (
    <div className="planning-box">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item xs={"auto"} sm={"auto"}>
              <Header
                title={
                  selectedDashboard
                    ? `Dashboard: ${selectedDashboard.description}`
                    : ""
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          {ComponentToRender}
        </Grid>
      </Grid>
    </div>
  );
}

export default PlanningMainComponent;
