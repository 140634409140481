import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export function formatDate(date) {
  let dateFormat;
  try {
    dateFormat = date
      ? new Date(date).toLocaleDateString("en-UK", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
      : "No Date";
  } catch (error) {
    dateFormat = "Invalid Date";
  }
  return dateFormat;
}

export async function makeAuthenticatedApiCall(
  url,
  method,
  body = null,
  thunkAPI
) {
  const localStorageTokens = localStorage.getItem("authTokens");
  const sessionStorageTokens = sessionStorage.getItem("authTokens");
  const authTokens = JSON.parse(
    localStorageTokens || sessionStorageTokens || "{}"
  );

  if (!authTokens.access) {
    return thunkAPI.rejectWithValue("No access token found");
  }

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${authTokens.access}`,
  };

  const options = {
    method,
    headers,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const res = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, options);
  return res;
}

export function formatRelativeTime(dateStr) {
  const date = new Date(dateStr);
  const now = new Date();

  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days} day${days !== 1 ? "s" : ""} ago`;
  if (hours > 0) return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  if (minutes > 0) return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  return "just now";
}

export const calculateDaysBetween = (startDate, endDate) => {
  if (!startDate) {
    return 0;
  }

  let start = new Date(startDate);
  const end = new Date(endDate);
  let count = 0;

  while (start < end) {
    if (start.getDay() !== 0 && start.getDay() !== 6) {
      // Skip Sunday (0) and Saturday (6)
      count++;
    }
    start = new Date(start.setDate(start.getDate() + 1)); // Increment date by 1
  }

  return count;
};

export const getNearestMonday = () => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // Sunday - 0, Monday - 1, ..., Saturday - 6

  if (dayOfWeek === 0) {
    // If it's Sunday, set to next Monday
    return new Date(today.setDate(today.getDate() + 1));
  } else if (dayOfWeek === 6) {
    // If it's Saturday, set to next Monday
    return new Date(today.setDate(today.getDate() + 2));
  } else {
    // For any other day, set to this week's Monday
    return new Date(today.setDate(today.getDate() - (dayOfWeek - 1)));
  }
};

export const getNearestFriday = () => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // Sunday - 0, Monday - 1, ..., Saturday - 6

  if (dayOfWeek === 0) {
    // If it's Sunday, set to next Friday
    return new Date(today.setDate(today.getDate() + 5));
  } else if (dayOfWeek === 6) {
    // If it's Saturday, set to next Friday
    return new Date(today.setDate(today.getDate() + 6));
  } else {
    // For any other day, set to this week's Friday
    return new Date(today.setDate(today.getDate() + (5 - dayOfWeek)));
  }
};

export const exportPDF = () => {
  const input = document.getElementById("powerbi-export");
  html2canvas(input, { scale: 2 }) // Adjust scale as needed for quality
    .then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("powerbi-report.pdf");
    });
};
