import { createSlice } from '@reduxjs/toolkit'
import { PBI_MENU } from '../hubl/utils/types'

const selectDashboardSectionSlice = createSlice({
  name: 'selectedDashboardSection',
  initialState: {section: PBI_MENU},
  reducers: {
      selectDashboardSection: (state, action) => {
        state.section = action.payload
      },
    },
  })

export const { selectDashboardSection } = selectDashboardSectionSlice.actions
export default selectDashboardSectionSlice.reducer

