import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { postFilterType } from "slice/admin";
import { useDispatch } from "react-redux";
import TextFieldsComponent from "../../components/textfield/TextField";

function PostFilterTypeComponent() {
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    table: "",
    field: "",
  });
  const dispatch = useDispatch();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(postFilterType(formData)).then((e) => {
      if (!e.error) {
        setFormData({
          name: "",
          type: "",
          table: "",
          field: "",
        });
      }

      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <TextFieldsComponent
          title="Name"
          name="name"
          placeholder="filter name"
          value={formData.name}
          onChange={handleInputChange}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        />

        <TextFieldsComponent
          title="Type"
          name="type"
          placeholder="filter type"
          value={formData.type}
          onChange={handleInputChange}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        />
        <TextFieldsComponent
          title="Table"
          name="table"
          placeholder="filter table"
          value={formData.table}
          onChange={handleInputChange}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        />

        <TextFieldsComponent
          title="Field"
          name="field"
          placeholder="filter field"
          value={formData.field}
          onChange={handleInputChange}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        />

        <Grid item xs={12} sm={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            Submit
          </Button>{" "}
        </Grid>
      </Grid>
    </form>
  );
}

export default PostFilterTypeComponent;
