import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Col, Container, Row } from "reactstrap";

const SectionContact = () => {
  const [isOpen, setOpen] = useState(false);

  const styleObj = {
    letterSpacing: "2px",
    fontWeight: "700",
  };

  const styleData = {
    letterSpacing: "1px",
    fontSize: "22px",
    fontWeight: "400",
  };

  return (
    <React.Fragment>
      <section className="bg-home14" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center text-white">
                {/* <h2 className="mt-5" style={styleObj}>
                  Contact Us
                </h2> */}

                {/* <h5 style={styleData}>
                  Our team of data engineers and visualisation specialists work
                  with you to <span className="text-primary">transform</span>{" "}
                  your organisational data into{" "}
                  <span className="text-primary">strategic insights</span> that
                  enables{" "}
                  <span className="text-primary">data-driven decision </span>
                  making.
                </h5> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="position-relative">
        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1440"
            height="150"
            preserveAspectRatio="none"
            viewBox="0 0 1440 150"
          >
            <defs>
              <mask id="SvgjsMask1022">
                <rect width="1440" height="250" fill="#ffffff"></rect>
              </mask>
            </defs>
          </svg>
        </div>
      </div>
      <ModalVideo
        channel="vimeo"
        videoId="287684225"
        autoplay
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default SectionContact;
